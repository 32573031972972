import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserComment } from '../models/user-comment.model';

@Injectable()
export class UserCommentService {
  public basePath: string = '/user-comment';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(data: UserComment): Promise<UserComment> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
