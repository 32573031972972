export enum NotificationAppTypeEnumerator {
  PROJET = 1,
  IEA = 2,
  LMS = 3,
}

export const notificationAppTypeEnumeratorMap:
  Map<NotificationAppTypeEnumerator, string> = new Map<NotificationAppTypeEnumerator, string>([
  [NotificationAppTypeEnumerator.PROJET, 'Projet'],
  [NotificationAppTypeEnumerator.IEA, 'Inovadores em ação'],
  [NotificationAppTypeEnumerator.LMS, 'Gestão de aprendizagem'],
]);

export const notificationAppTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(notificationAppTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));