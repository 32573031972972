export enum TestStatusEnumerator {
  NOT_AVAILABLE = 1,
  TODO = 2,
  WAITING_REVIEW = 3,
  DONE = 4,
  REDO = 5,
}

// tslint:disable:max-line-length
export const testStatusEnumeratorEnumeratorMap: Map<TestStatusEnumerator, string> = new Map<TestStatusEnumerator, string>([
  [TestStatusEnumerator.NOT_AVAILABLE, 'Não disponível'],
  [TestStatusEnumerator.TODO, 'A fazer'],
  [TestStatusEnumerator.WAITING_REVIEW, 'Aguardando Revisão'],
  [TestStatusEnumerator.DONE, 'Aprovado'],
  [TestStatusEnumerator.REDO, 'Refazer'],
]);

export const testStatusEnumeratorList: Array<any> =
  Array.from(testStatusEnumeratorEnumeratorMap)
    .map((o: Array<any>) => {
      const color: string = getStatusColor(o[0]);

      return {
        id: o[0],
        description: o[1],
        color,
      };
    });

function getStatusColor(key: any): string {
  let color: string = null;

  switch (key) {
    case TestStatusEnumerator.NOT_AVAILABLE:
      color = 'rgb(180, 180, 180)';
      break;
    case TestStatusEnumerator.TODO:
      color = 'rgb(56, 101, 163)';
      break;
    case TestStatusEnumerator.WAITING_REVIEW:
      color = 'rgb(255, 204, 1)';
      break;
    case TestStatusEnumerator.DONE:
      color = 'rgb(100, 163, 53)';
      break;
    case TestStatusEnumerator.REDO:
      color = 'rgb(225, 59, 36)';
      break;
  }

  return color;
}