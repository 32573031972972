export enum CertificateReleaseTypeEnumerator {
  AUTOMATIC = 1,
  MANUAL = 2,
}

export const certificateReleaseTypeEnumeratorMap:
  Map<CertificateReleaseTypeEnumerator, string> = new Map<CertificateReleaseTypeEnumerator, string>([
    [CertificateReleaseTypeEnumerator.AUTOMATIC, 'Automático'],
    [CertificateReleaseTypeEnumerator.MANUAL, 'Manual'],
]);

export const certificateReleaseTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(certificateReleaseTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));