import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class LoggedUserService {
  private subject: Subject<User> = new Subject<User>();

  public get(): Observable<User> {
    return this.subject.asObservable();
  }

  public set(data: User): void {
    this.subject.next(data);
  }
}
