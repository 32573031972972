import { BaseModel, SearchParameter } from './base.model';
import { Course } from './course.model';

export class Products extends BaseModel {
    name?: string;
    routeName?: string;
    courseIdList?: Array<Course>;
    primaryColor?: string;
    secondaryColor?: string;
    answerFeedbackColor?: string;
    logoIconUrl?: string;
    logoUrl?: string;
    mainBannerUrl?: string;
}

export class ProductsSearchParameter extends SearchParameter {
    id?: string;
    name?: string;
    type?: number;
  }