import { AcademicSegment } from 'app/core';

enum keys {
  academicSegments = 'academicSegments',
}

const segments: Array<AcademicSegment> = window.localStorage[keys.academicSegments]
  ? JSON.parse(window.localStorage[keys.academicSegments])
  : null;

export enum ClassSegmentEnumerator {
  INFANTIL = 1,
  ANOS_INICIAIS = 2,
  ANOS_FINAIS = 3,
  ENSINOMEDIO = 4,
}

export const classSegmentEnumeratorMap: Map<ClassSegmentEnumerator, string> = new Map<ClassSegmentEnumerator, string>([
  [ClassSegmentEnumerator.INFANTIL, segments
    ? segments.find((o: AcademicSegment) => o.key === ClassSegmentEnumerator.INFANTIL).name
    : 'Infantil',
  ],
  [ClassSegmentEnumerator.ANOS_INICIAIS, segments
    ? segments.find((o: AcademicSegment) => o.key === ClassSegmentEnumerator.ANOS_INICIAIS).name
    : 'Anos iniciais',
  ],
  [ClassSegmentEnumerator.ANOS_FINAIS, segments
    ? segments.find((o: AcademicSegment) => o.key === ClassSegmentEnumerator.ANOS_FINAIS).name
    : 'Anos finais',
  ],
  [ClassSegmentEnumerator.ENSINOMEDIO, segments
    ? segments.find((o: AcademicSegment) => o.key === ClassSegmentEnumerator.ENSINOMEDIO).name
    : 'Ensino médio',
  ],
]);

export const classSegmentEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(classSegmentEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
