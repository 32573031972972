import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './core';
import { language } from './i18n/pt-br';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _translateService: TranslateService,
    private _configService: ConfigService,
  ) {
    this._translateService.addLangs(['pt-br']);
    this._translateService.setDefaultLang('pt-br');
    this._translateService.setTranslation('pt-br', language.data, true);
    this._translateService.use('pt-br');
    setTimeout(() => {
      this._translateService.use('pt-br');
    });
  }

  async ngOnInit(): Promise<void> {
    const configs = await this._configService.getConfig();
    this._configService.setConfigsOnStorage(configs);
  }
}
