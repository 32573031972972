export enum TaskTypeEnumerator {
  TEXT = 1,
  CHECK = 2,
  RADIO = 3,
  FILE_UPLOAD = 4,
  VIDEO_UPLOAD = 5,
  PHOTO_UPLOAD = 6,
  MULTIPLE_TEXTS = 7,
  QUIZ = 8,
}

export const taskTypeEnumeratorMap: Map<TaskTypeEnumerator, string> = new Map<TaskTypeEnumerator, string>([
  [TaskTypeEnumerator.TEXT, 'Texto'],
  [TaskTypeEnumerator.CHECK, 'Múltipla escolha'],
  [TaskTypeEnumerator.RADIO, 'Escolha única'],
  [TaskTypeEnumerator.FILE_UPLOAD, 'Envio de áudio'],
  [TaskTypeEnumerator.VIDEO_UPLOAD, 'Envio de vídeo'],
  [TaskTypeEnumerator.PHOTO_UPLOAD, 'Envio de imagem'],
  [TaskTypeEnumerator.MULTIPLE_TEXTS, 'Múltiplas perguntas'],
  [TaskTypeEnumerator.QUIZ, 'Quiz'],
]);

export const taskTypeEnumeratorList: Array<any> =
  Array.from(taskTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));