import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserPrivateChat, UserPrivateChatSearchParameter } from '../models/user-private-chat.model';
import { Pagination } from '../models';

@Injectable()
export class UserPrivateChatService {
  public basePath: string = '/user-private-chat';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public getAll(searchParameter: any): Promise<any> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  async update(id: any, userPrivateChat: UserPrivateChat): Promise<UserPrivateChat> {
    if (userPrivateChat) {
      userPrivateChat = await this._apiService.put(`${this.basePath}/${id}`, userPrivateChat).toPromise();
    }
    return userPrivateChat;
  }
}
