import { ApiService } from './api.service';
import { AcademicYear, Pagination } from '../models';
import { Injectable } from '@angular/core';

enum keys {
  academicYears = 'academicYears',
}

@Injectable()
export class AcademicYearService {
  public basePath: string = '/academic-year';

  constructor(private _apiService: ApiService) { }

  public getSelectives(): Promise<Array<AcademicYear>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, academicYear: AcademicYear): Promise<AcademicYear> {
    if (academicYear) {
      academicYear = await this._apiService.put(`${this.basePath}/${id}`, academicYear).toPromise();
    }
    return academicYear;
  }

  public setAcademicYearsOnStorage(academicYears: Array<AcademicYear>): void {
    window.localStorage[keys.academicYears] = JSON.stringify(academicYears);
  }

  public getAcademicYearsOnStorage(): Array<AcademicYear> | null {
    return window.localStorage[keys.academicYears] ? JSON.parse(window.localStorage[keys.academicYears]) : null;
  }
}