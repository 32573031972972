export class BaseModel {
  id?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  deletedAt?: Date | string | null;
  createBy?: string;
  updateBy?: string;
}

export class SearchParameter {
  static defaultPageSize: number = 10;
  static pageSizeGrid: number = 12;
  static maxPageSize: number = 100;
  static defaultPageSizeOptions: Array<number> = [10, 25, 100];
  offset?: number;
  limit?: number;
  orderBy?: string;
  isDESC?: boolean;
}

export class Pagination<T> {
  count?: number;
  rows?: Array<T>;
}
