export enum UserCommentTypeEnumerator {
  ACTIVITY_QUESTION_ANSWER = 1,
  CLOSURE_TASK_ANSWER = 2,
}

export const userCommentTypeEnumeratorMap:
  Map<UserCommentTypeEnumerator, string> = new Map<UserCommentTypeEnumerator, string>([
  [UserCommentTypeEnumerator.ACTIVITY_QUESTION_ANSWER, 'Resposta na questão da atividade'],
  [UserCommentTypeEnumerator.CLOSURE_TASK_ANSWER, 'Resposta na tarefa final'],
]);

export const userCommentTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(userCommentTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
