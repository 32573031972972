import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Module } from '../models/module.model';

@Injectable()
export class ModuleService {
  public basePath: string = '/module';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(module: Module): Promise<Module> {
      return this._apiService
      .post(`${this.basePath}`, module).toPromise();
  }

  public getById(id: string): Promise<Module> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: any, module: Module): Promise<Module> {
    if (module) {
      module = await this._apiService.put(`${this.basePath}/${id}`, module).toPromise();
    }
    return module;
  }

  async addContent(id: any, contentId: string): Promise<void> {
    if (contentId) {
      await this._apiService.put(`${this.basePath}/${id}/add-content`, { contentId }).toPromise();
    }
  }

  async removeContentById(id: any, contentId: string): Promise<void> {
    if (contentId) {
      await this._apiService.put(`${this.basePath}/${id}/remove-content`, { contentId }).toPromise();
    }
  }

  async updateContentsOrder(id: any, contentIdList: Array<string>): Promise<void> {
    if (contentIdList) {
      await this._apiService.put(`${this.basePath}/${id}/contents-order`, contentIdList).toPromise();
    }
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
