import { BaseModel, SearchParameter } from './base.model';

export class VirtualGroup extends BaseModel {
    id?: string;
    name?: string;
    code?: string;
    type?: number;
    schoolId?: string;
    capacity?: string;
    users?: any;
    closedAt?: any;
}

export class VirtualGroupSearchParameter extends SearchParameter {
    name?: string;
    code?: string;
    schoolId?: string;
    courseId?: string;
    tutorId?: string;
    type?: number;
    available?: boolean;
  }