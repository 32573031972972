export enum MissionTypeEnumerator {
  ODS = 1,
  COMPETENCE = 2,
  CAMPAIGN = 3,
  PROJET = 4,
}

export const missionTypeEnumeratorMap: Map<MissionTypeEnumerator, string> = new Map<MissionTypeEnumerator, string>([
  [MissionTypeEnumerator.ODS, 'ODS'],
  [MissionTypeEnumerator.COMPETENCE, 'Campanha'],
  [MissionTypeEnumerator.CAMPAIGN, 'Competência'],
  [MissionTypeEnumerator.PROJET, 'Projet'],
]);

export const MissionTypeEnumeratorList: Array<any> =
  Array.from(missionTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
