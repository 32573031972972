export enum ConfigTypeEnumerator {
  TERMS_UPDATE_DATE = 1,
  FAQ = 4,
  CERTIFICATE_PERCENT_CONTENT_VIEWED = 5,
  CERTIFICATE_PERCENT_ACTIVITIES_DONE = 6,
  CERTIFICATE_TEST_GRADE = 7,
  DIRECTOR_PLENO_NAME = 8,
  DIRECTOR_PLENO_SIGNATURE = 9,
}

export const configTypeEnumeratorMap: Map<ConfigTypeEnumerator, string> =
  new Map<ConfigTypeEnumerator, string>([
    [ConfigTypeEnumerator.TERMS_UPDATE_DATE, 'Data de atualização de termos'],
    [ConfigTypeEnumerator.FAQ, 'FAQ'],
    [
      ConfigTypeEnumerator.CERTIFICATE_PERCENT_CONTENT_VIEWED,
      'Conteúdos mínimos visualizados padrão para a liberação do certificado em porcentagem',
    ],
    [
      ConfigTypeEnumerator.CERTIFICATE_PERCENT_ACTIVITIES_DONE,
      'Atividades mínimas realizadas padrão para a liberação do certificado em porcentagem',
    ],
    [
      ConfigTypeEnumerator.CERTIFICATE_TEST_GRADE,
      'Nota mínima na prova padrão para a liberação do certificado em porcentagem',
    ],
  ]);

export const configTypeEnumeratorList:
  Array<{ id: number; description: string; }> = Array.from(configTypeEnumeratorMap).map((o: Array<any>) => ({
    id: o[0],
    description: o[1],
  }));
