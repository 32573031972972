import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading-input',
  templateUrl: './loading-input.component.html',
  styleUrls: ['./loading-input.component.scss'],
})
export class LoadingInputComponent implements OnInit {
  constructor() {
    // empty
  }

  ngOnInit(): void {
  }
}