export enum PlatformTypeEnumerator {
  APP = 1,
  WEB = 2,
}

export const platformTypeEnumeratorMap:
  Map<PlatformTypeEnumerator, string> = new Map<PlatformTypeEnumerator, string>([
  [PlatformTypeEnumerator.APP, 'Aplicativo'],
  [PlatformTypeEnumerator.WEB, 'Web'],
]);

export const platformTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(platformTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));