import { BaseModel, SearchParameter } from './base.model';

export class SchoolCourse extends BaseModel {
    schoolId?: string;
    courseId?: string;
}

export class SchoolCourseSearchParameter extends SearchParameter {
    title?: string;
    type?: number;
    schoolId?: string;
  }