import { BaseModel, SearchParameter } from './base.model';

export class SatisfactionSurvey extends BaseModel {
    id?: string;
    title?: string;
    description?: string;
    type?: number;
    questions?: Array<any>;
}

export class SatisfactionSurveySearchParameter extends SearchParameter {
    title?: string;
    description?: string;
    type?: number;
  }