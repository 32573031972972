import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Mission, Pagination, MissionSearchParameter } from '../models/index';

@Injectable()
export class MissionService {
  public basePath: string = '/mission';

  constructor(
    private _apiService: ApiService,
  ) { }

  public create(mission: Mission): Promise<Mission> {
    return this._apiService
    .post(`${this.basePath}`, mission).toPromise();
}

  public getAll(searchParameter: MissionSearchParameter = null): Promise<Pagination<Mission>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getById(id: string): Promise<Mission> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getWithTasks(id: string): Promise<Mission> {
    return this._apiService
      .get(`${this.basePath}/${id}/with-task`).toPromise();
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: any, mission: Mission): Promise<Mission> {
    if (mission) {
      mission = await this._apiService.put(`${this.basePath}/${id}`, mission).toPromise();
    }
    return mission;
  }

}