import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { UserPost, UserPostSearchParameter } from '../models/user-post.model';
import { Pagination } from '../models';

@Injectable()
export class UserPostService {
  public basePath: string = '/user-post';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(data: UserPost): Promise<UserPost> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public updateById(id: string, data: UserPost): Promise<UserPost> {
    return this._apiService
      .put(`${this.basePath}/${id}`, data).toPromise();
  }

  public getAll(searchParameter: UserPostSearchParameter = null): Promise<Pagination<UserPost>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}