import { BaseModel } from './base.model';

export class Banner extends BaseModel {
  applicationType: number;
  photoUrlDesktop: string;
  photoUrlMobile: string;
  link: string;
  name?: string;
  url?: string;
  index?: number;
  idBrand?: string;
}
