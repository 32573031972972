import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Task, TaskSearchParameter } from '../models/task.model';
import { Pagination } from '../models';

@Injectable()
export class TaskService {
  public basePath: string = '/task';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(data: Task): Promise<Task> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public updateById(id: string, data: Task): Promise<Task> {
    return this._apiService
      .put(`${this.basePath}/${id}`, data).toPromise();
  }

  public getById(id: string): Promise<Task> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getAll(searchParameter: TaskSearchParameter = null): Promise<Pagination<Task>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public delete(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}