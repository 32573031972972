import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Pagination, UserSearchParameter } from '../models';
import { TaskAnswerSearchParameter, TaskAnswer } from '../models/task-answer.model';

@Injectable()
export class TaskAnswerService {
  public basePath: string = '/task-answer';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public getById(id: string): Promise<TaskAnswer> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getAll(searchParameter: TaskAnswerSearchParameter = null): Promise<Pagination<TaskAnswer>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public export(searchParameter: UserSearchParameter = null): any {
    return this._apiService.get(`${this.basePath}/export`, sanitizeGet(searchParameter)).toPromise();
  }
}
