import { Address } from './address.model';
import { SearchParameter, BaseModel } from './base.model';
import { BusinessPlan } from './business-plan.model';

export class SchoolCapacity {
  id: string;
  year: number;
  availableUsersAmount: number | undefined;
  type: number;
  studentEnrollmentPermit: boolean | null;
}

export class School extends BaseModel {
  id: string;
  brandId: string;
  schoolUnitId: string;
  name: string;
  sasId?: any;
  businessPlanId: string;
  corporateName?: any;
  phoneNumber?: any;
  contactName?: any;
  email?: any;
  documentType?: any;
  documentNumber?: any;
  studentEnrollmentPermit: boolean | null;
  courseIdList?: Array<string>;
  type: string;
  consultantIdList?: Array<string>;
  capacityList?: Array<SchoolCapacity>;
  staffCapacityList?: Array<SchoolCapacity>;
  businessPlan?: BusinessPlan;
  testGradeReleaseType?: number;
  contractEndAt?: string;
  address?: Address;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedAt: Date | null;
  integrationStatus: number;
  idSas: string | null;
  idSae: string | null;
  idSchoolUnit: string | null;
  idBusinessPlan: string;
  idBrand: string | null;
  idArco: string;
}

export class SchoolSearchParameter extends SearchParameter {
  limit?: number;
  name?: string;
  type?: string;
  idList?: any;
  consultantIdList?: Array<string>;
  haveSasId?: boolean;
}
