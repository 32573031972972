import { Environment } from "./environment.model";

let apiUrl: any;
let apiUrlNew: string;
let reactUrl: string;
let portalUrl: string;
let lmsProductUrl: string;
let brandDomain: string;
let brandCookieDomain: string;

const subdomain: string =
  window.location.hostname.split(".").length &&
  window.location.hostname.split(".")[0];
const brandName: string =
  window.location.hostname.split(".").length &&
  window.location.hostname.split(".")[1];
const brandDomainCurrent: string = window.location.hostname
  .split(".")
  .slice(1)
  .join(".");

switch (subdomain) {
  case "localhost":
    apiUrl = "https://api-legacy-dev.arco-nse.com";
    apiUrlNew = "https://api-dev.arco-nse.com/v1";
    reactUrl = "http://localhost:3535";
    portalUrl = "http://localhost:7080";
    lmsProductUrl = "http://localhost:7083";
    brandDomain = "escoladainteligencia.com.br";
    brandCookieDomain = "localhost";
    break;
  case "gestaolmsdev":
    apiUrl = "https://api-legacy-dev.arco-nse.com";
    apiUrlNew = "https://api-dev.arco-nse.com/v1";
    reactUrl = `https://dashboarddev.${brandName}.com.br`;
    portalUrl = `https://portaldev.${brandName}.com.br`;
    lmsProductUrl = `https://lmsdev.${brandName}.com.br`;
    brandDomain = brandDomainCurrent;
    brandCookieDomain = `.${brandDomain}`;
    break;
  case "gestaolmshmg":
    apiUrl = "https://api-legacy-hmg.arco-nse.com";
    apiUrlNew = "https://api-hmg.arco-nse.com/v1";
    reactUrl = `https://dashboardhmg.${brandName}.com.br`;
    portalUrl = `https://portalhmg.${brandName}.com.br`;
    lmsProductUrl = `https://lmshmg.${brandName}.com.br`;
    brandDomain = brandDomainCurrent;
    brandCookieDomain = `.${brandDomain}`;
    break;
  case "gestaolms":
    apiUrl = "https://api-prod-legacy.arco-nse.com";
    apiUrlNew = "https://api-prod.arco-nse.com/v1";
    reactUrl = `https://dashboard.${brandName}.com.br`;
    portalUrl = `https://portal.${brandName}.com.br`;
    lmsProductUrl = `https://lms.${brandName}.com.br`;
    brandDomain = brandDomainCurrent;
    brandCookieDomain = `.${brandDomain}`;
    break;
}

export const environment: Environment = {
  production: false,
  apiUrl,
  apiUrlNew,
  reactUrl,
  portalUrl,
  lmsProductUrl,
  brandDomain,
  brandCookieDomain,
};
