export enum SchoolTypeEnumerator {
  PLENO = 1,
  SAS = 2,
}

export const schoolTypeEnumeratorMap: Map<SchoolTypeEnumerator, string> = new Map<SchoolTypeEnumerator, string>([
  [SchoolTypeEnumerator.PLENO, 'Pleno'],
  [SchoolTypeEnumerator.SAS, 'SAS'],
]);

export const schoolTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(schoolTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
