import { ApiService } from './api.service';
import { AcademicSegment, Pagination } from '../models';
import { Injectable } from '@angular/core';

enum keys {
  academicSegments = 'academicSegments',
}

@Injectable()
export class AcademicSegmentService {
  public basePath: string = '/academic-segment';

  constructor(private _apiService: ApiService) { }

  public getSelectives(): Promise<Array<AcademicSegment>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, academicSegment: AcademicSegment): Promise<AcademicSegment> {
    if (academicSegment) {
      academicSegment = await this._apiService.put(`${this.basePath}/${id}`, academicSegment).toPromise();
    }
    return academicSegment;
  }

  public setAcademicSegmentsOnStorage(academicSegments: Array<AcademicSegment>): void {
    window.localStorage[keys.academicSegments] = JSON.stringify(academicSegments);
  }

  public getAcademicSegmentsOnStorage(): Array<AcademicSegment> | null {
    return window.localStorage[keys.academicSegments] ? JSON.parse(window.localStorage[keys.academicSegments]) : null;
  }
}