import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule, MatPaginationIntlService } from 'app/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'app/shared';
import { ContainersModule } from 'app/containers/containers.module';
import { FeatherModule } from 'angular-feather';
import { SortablejsModule } from 'angular-sortablejs';
import { QuillModule } from 'ngx-quill';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  Box,
  Book,
  User,
  Users,
  Calendar,
  ArrowLeft,
  ArrowRight,
  Search,
  MapPin,
  Menu,
  Trash,
  Trash2,
  Edit,
  Edit3,
  Check,
  Eye,
  EyeOff,
  Smartphone,
  Battery,
  Lock,
  LogOut,
  ExternalLink,
  X,
  BookOpen,
  Upload,
  Download,
  FileText,
  Film,
  Image,
  Volume2,
  Trello,
  Grid,
  List,
  Globe,
  Home,
  Star,
  PieChart,
  Folder,
  Smile,
  File,
  Clipboard,
  TrendingUp,
  MoreVertical,
  PlusSquare,
  Crosshair,
  Flag,
  MessageCircle,
  MessageSquare,
  HelpCircle,
  Aperture,
  Clock,
  ChevronDown,
  ChevronUp,
  Filter,
  UserCheck,
  Heart,
  Video,
  ChevronRight,
  ChevronLeft,
  Send,
  Settings,
  AlertTriangle,
  Repeat,
  PlayCircle,
  Play,
  AlignCenter,
  CheckSquare,
  Tag,
  LogIn,
  CheckCircle,
  BarChart2,
  Layout,
  Info,
  Plus,
  Award,
  Airplay,
} from 'angular-feather/icons';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material';
import localePt from '@angular/common/locales/pt';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const icons: any = {
  Box,
  Book,
  User,
  Users,
  Calendar,
  ArrowLeft,
  ArrowRight,
  Search,
  MapPin,
  Menu,
  Trash,
  Trash2,
  Edit,
  Edit3,
  Check,
  Eye,
  EyeOff,
  Smartphone,
  Battery,
  Lock,
  LogOut,
  ExternalLink,
  X,
  BookOpen,
  Upload,
  Download,
  FileText,
  Film,
  Image,
  Volume2,
  Trello,
  Grid,
  List,
  Globe,
  Home,
  Star,
  PieChart,
  Folder,
  Smile,
  File,
  Clipboard,
  TrendingUp,
  MoreVertical,
  PlusSquare,
  Crosshair,
  Flag,
  MessageCircle,
  MessageSquare,
  HelpCircle,
  Aperture,
  Clock,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
  Filter,
  UserCheck,
  Heart,
  Video,
  Send,
  Settings,
  AlertTriangle,
  PlayCircle,
  Play,
  AlignCenter,
  CheckSquare,
  Tag,
  LogIn,
  CheckCircle,
  BarChart2,
  Layout,
  Repeat,
  Info,
  Plus,
  Award,
  Airplay,
};

registerLocaleData(localePt);

export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule.forRoot(),
    ContainersModule,
    FeatherModule.pick(icons),
    TranslateModule.forRoot(),
    NgxCoolDialogsModule.forRoot({
      color: '#96539c',
    }),
    NgxPermissionsModule.forRoot(),
    NgxMaskModule.forRoot(options),
    SortablejsModule.forRoot({ animation: 150 }),
    QuillModule.forRoot(),
    NgxMatSelectSearchModule,
    NgxChartsModule,
  ],
  exports: [
    FeatherModule,
    QuillModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MatPaginatorIntl, useClass: MatPaginationIntlService },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
})
export class AppModule {
}
