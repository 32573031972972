import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Course, CourseSearchParameter } from '../models/course.model';
import { User, UserSearchParameter } from '../models/user.model';
import { Pagination } from '../models';

@Injectable()
export class CourseService {
  public basePath: string = '/course';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(course: Course): Promise<Course> {
      return this._apiService
      .post(`${this.basePath}`, course).toPromise();
  }

  public getById(id: string): Promise<Course> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getCompleteById(id: string): Promise<Course> {
    return this._apiService
        .get(`${this.basePath}/${id}/complete`).toPromise();
  }

  public getPaginated(searchParameter: CourseSearchParameter = null): Promise<Pagination<Course>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getSelectives(searchParameter: CourseSearchParameter = null): Promise<Pagination<Course>> {
    return this._apiService
      .get(`${this.basePath}/selectives`, sanitizeGet(searchParameter)).toPromise();
  }

  public getUsers(id: string, searchParameter: UserSearchParameter = null): Promise<Pagination<User>> {
    return this._apiService
      .get(`${this.basePath}/${id}/users`, sanitizeGet(searchParameter)).toPromise();
  }

  async update(id: any, course: Course): Promise<Course> {
    if (course) {
      course = await this._apiService.put(`${this.basePath}/${id}`, course).toPromise();
    }
    return course;
  }

  async updateModulesOrder(id: any, moduleIdList: Array<string>): Promise<void> {
    if (moduleIdList) {
      await this._apiService.put(`${this.basePath}/${id}/modules-order`, moduleIdList).toPromise();
    }
  }

  async addUsers(id: any, data: any): Promise<void> {
    if (data) {
      await this._apiService.put(`${this.basePath}/${id}/add-users`, data).toPromise();
    }
  }

  async removeUsers(id: any, userIdList: Array<string>): Promise<void> {
    if (userIdList) {
      await this._apiService.put(`${this.basePath}/${id}/remove-users`, { userIdList }).toPromise();
    }
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
