import { NgModule } from '@angular/core';
import { ContainersRoutingModule } from 'app/containers/containers-routing.module';
import { ContainersComponent } from 'app/containers/containers.component';
import { SharedModule } from 'app/shared';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';

const CONTAINER_COMPONENTS: any = [
  ContainersComponent,
];

@NgModule({
  imports: [
    ContainersRoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      },
    },
  ],
  declarations: [
    ...CONTAINER_COMPONENTS,
  ],
})
export class ContainersModule {
}