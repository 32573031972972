import { BaseModel, SearchParameter } from "./base.model";

export class CourseTest extends BaseModel {
  id?: string;
  title?: string;
  type?: number;
  percForApproval?: string;
  description?: string;
  questions?: Array<any>;
  firstSemesterEndAt?: string;
  firstSemesterStartAt?: string;
  secondSemesterEndAt?: string;
  secondSemesterStartAt?: string;
}

export class CourseTestSearchParameter extends SearchParameter {
  title?: string;
  description?: string;
}
