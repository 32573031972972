import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Like } from '../models/like.model';

@Injectable()
export class LikeService {
  public basePath: string = '/user-post-like';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(data: any): Promise<Like> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}