import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from 'app/core/interceptors';
import {
  AuthService,
  LoggedUserService,
  DashboardService,
  UserService,
  ApiService,
  StudentClassService,
  SchoolService,
  OdsService,
  CompetenceService,
  OdsContentService,
  EmotionService,
  TaskService,
  UtilsService,
  QuizCompetenceService,
  MissionService,
  TaskAnswerService,
  CampaignService,
  NotificationService,
  ProjetService,
  TaskAnswerCommentService,
  UserPostService,
  MissionScheduleService,
  UserMissionService,
  LikeService,
  UserPrivateChatService,
  PrivateChatService,
  UserPrivateChatMessageService,
  ConfigService,
  CourseService,
  ModuleService,
  TagService,
  ContentService,
  ActivityService,
  ActivityQuestionService,
  BannerService,
  UserCommentService,
  CourseTestService,
  CourseTestQuestionService,
  CourseClosureTaskAnswerService,
  VirtualGroupService,
  SchoolCourseService,
  SatisfactionSurveyService,
  SatisfactionSurveyQuestionService,
  CertificateService,
  CertificateSettingsService,
  BusinessPlanService,
  AcademicYearService,
  AcademicSegmentService,
  BrandConfigService,
  SchoolUnitService,
  ProductsService,
  SchoolAcademicYearService,
} from 'app/core/services';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true},
    ApiService,
    AuthService,
    LoggedUserService,
    DashboardService,
    UserService,
    StudentClassService,
    SchoolService,
    OdsService,
    CompetenceService,
    OdsContentService,
    EmotionService,
    TaskService,
    UtilsService,
    QuizCompetenceService,
    MissionService,
    TaskAnswerService,
    CampaignService,
    NotificationService,
    ProjetService,
    TaskAnswerCommentService,
    UserPostService,
    MissionScheduleService,
    UserMissionService,
    LikeService,
    UserPrivateChatService,
    PrivateChatService,
    UserPrivateChatMessageService,
    ConfigService,
    CourseService,
    ModuleService,
    TagService,
    ContentService,
    ActivityService,
    ActivityQuestionService,
    BannerService,
    UserCommentService,
    CourseTestService,
    CourseTestQuestionService,
    CourseClosureTaskAnswerService,
    VirtualGroupService,
    SchoolCourseService,
    SatisfactionSurveyService,
    SatisfactionSurveyQuestionService,
    CertificateService,
    CertificateSettingsService,
    BusinessPlanService,
    AcademicYearService,
    AcademicSegmentService,
    BrandConfigService,
    SchoolUnitService,
    ProductsService,
    SchoolAcademicYearService,
  ],
  declarations: [],
})
export class CoreModule {
}
