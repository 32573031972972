import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { MissionSchedule, Pagination, MissionScheduleSearchParameter } from '../models/index';

@Injectable()
export class MissionScheduleService {
  public basePath: string = '/mission-schedule';

  constructor(
    private _apiService: ApiService,
  ) { }

  public create(mission: MissionSchedule): Promise<MissionSchedule> {
    return this._apiService
    .post(`${this.basePath}`, mission).toPromise();
}

public getById(id: string): Promise<MissionSchedule> {
  return this._apiService
    .get(`${this.basePath}/${id}`).toPromise();
}

  public getAll(searchParameter: MissionScheduleSearchParameter = null): Promise<Pagination<MissionSchedule>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  async update(id: any, mission: MissionSchedule): Promise<MissionSchedule> {
    if (mission) {
      mission = await this._apiService.put(`${this.basePath}/${id}`, mission).toPromise();
    }
    return mission;
  }

}