
export enum SatisfactionSurveyTypeEnumerator {
  CERTIFICATE = 1,
  DECLARATION = 2,
}

export const satisfactionSurveyTypeListEnumeratorMap = new Map<SatisfactionSurveyTypeEnumerator, string>([
  [SatisfactionSurveyTypeEnumerator.CERTIFICATE, 'Certificado'],
  [SatisfactionSurveyTypeEnumerator.DECLARATION, 'Declaração'],
]);

export const satisfactionSurveyTypeList = 
  Array.from(satisfactionSurveyTypeListEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));