export enum MaterialTypeEnumerator {
  PDF = 1,
  VIDEO = 2,
  IMAGE = 3,
  AUDIO = 4,
  OTHERS = 5,
  FOLDER = 6,
}

export const materialTypeEnumeratorMap:
  Map<MaterialTypeEnumerator, string> = new Map<MaterialTypeEnumerator, string>([
  [MaterialTypeEnumerator.PDF, 'PDF'],
  [MaterialTypeEnumerator.VIDEO, 'Video'],
  [MaterialTypeEnumerator.IMAGE, 'Imagem'],
  [MaterialTypeEnumerator.AUDIO, 'Audio'],
  [MaterialTypeEnumerator.OTHERS, 'Outros'],
  [MaterialTypeEnumerator.FOLDER, 'Pasta'],
]);

export const materialTypeEnumeratorIconMap:
Map<MaterialTypeEnumerator, string> = new Map<MaterialTypeEnumerator, string>([
  [MaterialTypeEnumerator.PDF, 'file-text'],
  [MaterialTypeEnumerator.VIDEO, 'film'],
  [MaterialTypeEnumerator.IMAGE, 'image'],
  [MaterialTypeEnumerator.AUDIO, 'volume-2'],
  [MaterialTypeEnumerator.OTHERS, 'trello'],
  [MaterialTypeEnumerator.FOLDER, 'folder'],
]);

export const materialTypeEnumeratorColorMap:
  Map<MaterialTypeEnumerator, string> = new Map<MaterialTypeEnumerator, string>([
  [MaterialTypeEnumerator.PDF, '#cc6600'],
  [MaterialTypeEnumerator.VIDEO, '#993399'],
  [MaterialTypeEnumerator.IMAGE, '#6666ff'],
  [MaterialTypeEnumerator.AUDIO, '#00cc33'],
  [MaterialTypeEnumerator.OTHERS, '#3399cc'],
  [MaterialTypeEnumerator.FOLDER, 'transparent'],
]);

export const materialTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(materialTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
