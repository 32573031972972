import { NgModule } from '@angular/core';
import { DocumentTypePipe } from './document-type.pipe';
import { MaterialContentTypePipe } from './material-content-type.pipe';
import { MaterialContentTypeColorPipe } from './material-content-type-color.pipe';
import { ProfileTypeEnumeratorPipe } from './profile-type.pipe';
import { SchoolTypeEnumeratorPipe } from './school-type.pipe';
import { EmotionTypePipe } from './emotion-type.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { MaterialContentTypeNamePipe } from './material-content-type-name.pipe';
import { ClassCollectionPipe } from './class-collection.pipe';
import { ClassSegmentPipe } from './class-segment.pipe';
import { ClassYearPipe } from './class-year.pipe';
import { SelectedTypeEnumeratorPipe } from './select-type.pipe';
import { TaskTypePipe } from './task-type.pipe';
import { EmotionTagPipe } from './emotion-tag.pipe';
import { NotificationTypePipe } from './notification-type.pipe';
import { LmsContentTypeEnumeratorPipe } from './lms-content-type.pipe';
import { ClosureTaskStatusPipe } from './closure-task-status.pipe';

@NgModule({
  declarations: [
    DocumentTypePipe,
    MaterialContentTypePipe,
    MaterialContentTypeNamePipe,
    MaterialContentTypeColorPipe,
    ProfileTypeEnumeratorPipe,
    SchoolTypeEnumeratorPipe,
    EmotionTypePipe,
    ClassCollectionPipe,
    ClassSegmentPipe,
    ClassYearPipe,
    SelectedTypeEnumeratorPipe,
    TaskTypePipe,
    EmotionTagPipe,
    SafeUrlPipe,
    NotificationTypePipe,
    LmsContentTypeEnumeratorPipe,
    ClosureTaskStatusPipe,
  ],
  exports: [
    DocumentTypePipe,
    MaterialContentTypePipe,
    MaterialContentTypeNamePipe,
    MaterialContentTypeColorPipe,
    ProfileTypeEnumeratorPipe,
    SchoolTypeEnumeratorPipe,
    EmotionTypePipe,
    ClassCollectionPipe,
    ClassSegmentPipe,
    ClassYearPipe,
    SelectedTypeEnumeratorPipe,
    TaskTypePipe,
    EmotionTagPipe,
    SafeUrlPipe,
    NotificationTypePipe,
    LmsContentTypeEnumeratorPipe,
    ClosureTaskStatusPipe,
  ],
  providers: [],
})
export class CustomPipesModule {

}
