import { ApiService, sanitizeGet } from './api.service';
import { SatisfactionSurveyQuestion, Pagination } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class SatisfactionSurveyQuestionService {
  public basePath: string = '/satisfaction-survey-question';

  constructor(private _apiService: ApiService) { }

  public create(satisfactionSurveyQuestion: SatisfactionSurveyQuestion): Promise<SatisfactionSurveyQuestion> {
    return this._apiService
      .post(`${this.basePath}`, satisfactionSurveyQuestion).toPromise();
  }

  public getById(id: string): Promise<SatisfactionSurveyQuestion> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: any = null): Promise<Pagination<any>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getSelectives(): Promise<Array<{ id: string, title: string }>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, satisfactionSurveyQuestion: SatisfactionSurveyQuestion): Promise<SatisfactionSurveyQuestion> {
    return this._apiService.put(`${this.basePath}/${id}`, satisfactionSurveyQuestion).toPromise();
  }

  async updateQuestionsOrder(id: any, questionIdList: Array<string>): Promise<void> {
    if (questionIdList) {
      await this._apiService.put(`${this.basePath}/${id}/questions-order`, questionIdList).toPromise();
    }
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}