import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Projet, ProjetSearchParameter } from '../models/projet.model';
import { Pagination } from '../models';

@Injectable()
export class ProjetService {
  public basePath: string = '/projet';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public getAll(searchParameter: ProjetSearchParameter = null): Promise<Pagination<Projet>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public getById(id: string): Promise<Projet> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getSelectives(): Promise<Array<{id: string, order: number; title: string}>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, projet: Projet): Promise<Projet> {
    if (projet) {
      projet = await this._apiService.put(`${this.basePath}/${id}`, projet).toPromise();
    }
    return projet;
  }
}
