import { ApiService } from './api.service';
import { CertificateSettings } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class CertificateSettingsService {
  public basePath: string = '/certificate-settings';

  constructor(private _apiService: ApiService) { }

  public create(certificateSettings: CertificateSettings): Promise<CertificateSettings> {
    return this._apiService
      .post(`${this.basePath}`, certificateSettings).toPromise();
  }

  public getById(id: string): Promise<CertificateSettings> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getMine(brandProductId: string): Promise<CertificateSettings> {
    return this._apiService.get(`${this.basePath}/mine`, { brandProductId }).toPromise();
  }

  async update(id: any, certificateSettings: CertificateSettings): Promise<CertificateSettings> {
    if (certificateSettings) {
      certificateSettings = await this._apiService.put(`${this.basePath}/${id}`, certificateSettings).toPromise();
    }
    return certificateSettings;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}