import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DashboardPage } from 'app/core';

@Injectable({providedIn: 'root'})
export class DashboardService {
  private subject: Subject<DashboardPage> = new Subject<DashboardPage>();

  public get(): Observable<DashboardPage> {
    return this.subject.asObservable();
  }

  public set(data: DashboardPage): void {
    this.subject.next(data);
  }
}
