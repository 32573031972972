import { ApiService } from './api.service';
import { Campaign, CampaignSearchParameter, Pagination } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class CampaignService {
  public basePath: string = '/campaign';

  constructor(private _apiService: ApiService) { }

  public create(campaign: Campaign): Promise<Campaign> {
    return this._apiService
      .post(`${this.basePath}`, campaign).toPromise();
  }

  public getById(id: string): Promise<Campaign> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: CampaignSearchParameter = null): Promise<Pagination<Campaign>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public getSelectives(): Promise<Array<{ id: string, title: string }>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, competence: Campaign): Promise<Campaign> {
    if (competence) {
      competence = await this._apiService.put(`${this.basePath}/${id}`, competence).toPromise();
    }
    return competence;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}