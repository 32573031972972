export enum ProfileTypeEnumerator {
  ADMIN = 1,
  TEACHER = 2,
  STUDENT = 3,
  DIRECTOR = 4,
  SCHOOL_ADMIN = 5,
  TEACHER_PROJET = 6,
  FAMILY = 8,
  ADVISER = 9,
  ADVISER_PROJET = 10,
  ATTENDANT = 11,
  EDOOLS = 12,
  TEACHER_IEA = 13,
  ADVISER_IEA = 15,
}

export const profileTypeEnumeratorMap: Map<ProfileTypeEnumerator, string> = new Map<ProfileTypeEnumerator, string>([
  [ProfileTypeEnumerator.ADMIN, 'Administrador'],
  [ProfileTypeEnumerator.STUDENT, 'Aluno'],
  [ProfileTypeEnumerator.TEACHER, 'Professor'],
  [ProfileTypeEnumerator.DIRECTOR, 'Diretor'],
  [ProfileTypeEnumerator.SCHOOL_ADMIN, 'Coordenador'],
  [ProfileTypeEnumerator.TEACHER_PROJET, 'Professor Projet'],
  [ProfileTypeEnumerator.FAMILY, 'Familiar'],
  [ProfileTypeEnumerator.ADVISER, 'Consultor'],
  [ProfileTypeEnumerator.ATTENDANT, 'Atendente'],
  [ProfileTypeEnumerator.EDOOLS, 'Edools'],
  [ProfileTypeEnumerator.TEACHER_IEA, 'Professor IEA'],
  [ProfileTypeEnumerator.ADVISER_IEA, 'Consultor IEA'],
]);

export const profileTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(profileTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));

export const isEducator: any = (profileType: number): boolean => [
  ProfileTypeEnumerator.TEACHER,
  ProfileTypeEnumerator.SCHOOL_ADMIN,
  ProfileTypeEnumerator.DIRECTOR,
].includes(profileType);
