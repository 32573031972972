import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { School, SchoolSearchParameter } from '../models/school.model';
import { Pagination } from '../models';

@Injectable()
export class SchoolService {
  public basePath: string = '/school';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(school: School): Promise<School> {
    return this._apiService
    .post(`${this.basePath}`, school).toPromise();
  }

  public getAssignBrandById(id: string): Promise<School> {
    return this._apiService
    .put(`${this.basePath}/${id}/assign-brand`).toPromise();
  }

  public getSelectives(): Promise<School> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<School> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getByDocumentNumber(documentNumber: number): Promise<School> {
    return this._apiService
        .get(`${this.basePath}/document-number/${documentNumber}`).toPromise();
  }

  // R
  public getPaginated(searchParameter: SchoolSearchParameter = null): Promise<Pagination<School>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  // U
  async update(id: any, school: School): Promise<School> {
    if (school) {
      school = await this._apiService.put(`${this.basePath}/${id}`, school).toPromise();
    }
    return school;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public getCertificateSettingsById(id: string, courseType: number): Promise<School> {
    return this._apiService
        .get(`${this.basePath}/${id}/certificate-settings`, { courseType }).toPromise();
  }
}
