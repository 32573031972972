import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PrivateChat, PrivateChatSearchParameter } from '../models/private-chat.model';
import { Pagination } from '../models';

@Injectable()
export class PrivateChatService {
  public basePath: string = '/private-chat';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(privateChat: PrivateChat): Promise<PrivateChat> {
    return this._apiService
    .post(`${this.basePath}`, privateChat).toPromise();
  }

  public getAll(searchParameter: PrivateChatSearchParameter = null): Promise<Pagination<PrivateChat>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  async update(id: any, privateChat: PrivateChat): Promise<PrivateChat> {
    if (privateChat) {
      privateChat = await this._apiService.put(`${this.basePath}/${id}`, privateChat).toPromise();
    }
    return privateChat;
  }
}
