export enum NotificationTypeEnumerator {
  GENERIC = 1,
  MISSION = 2,
  ODS = 3,
  CAMPAIGN = 4,
}

export const notificationTypeEnumeratorMap: Map<NotificationTypeEnumerator, string> =
  new Map<NotificationTypeEnumerator, string>([
    [NotificationTypeEnumerator.GENERIC, 'Genérico'],
    [NotificationTypeEnumerator.MISSION, 'Missão'],
    [NotificationTypeEnumerator.ODS, 'ODS'],
    [NotificationTypeEnumerator.CAMPAIGN, 'Campanha'],
  ]);

export const notificationTypeEnumeratorList:
  Array<{ id: number; description: string; }> = Array.from(notificationTypeEnumeratorMap).map((o: Array<any>) => ({
    id: o[0],
    description: o[1],
  }));
