import { ApiService, sanitizeGet } from './api.service';
import { SatisfactionSurvey, SatisfactionSurveySearchParameter, Pagination } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class SatisfactionSurveyService {
  public basePath: string = '/satisfaction-survey';

  constructor(private _apiService: ApiService) { }

  public create(satisfactionSurvey: SatisfactionSurvey): Promise<SatisfactionSurvey> {
    return this._apiService
      .post(`${this.basePath}`, satisfactionSurvey).toPromise();
  }

  public getById(id: string): Promise<SatisfactionSurvey> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getUsersReportById(id: string, searchParameter: any): Promise<any> {
    return this._apiService
      .get(`${this.basePath}/${id}/users-report`, sanitizeGet(searchParameter)).toPromise();
  }

  public getPaginated(searchParameter: SatisfactionSurveySearchParameter = null): Promise<Pagination<any>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getSelectives(): Promise<Array<{ id: string, title: string }>> {
    return this._apiService
      .get(`${this.basePath}/all`).toPromise();
  }

  async update(id: any, satisfactionSurvey: SatisfactionSurvey): Promise<SatisfactionSurvey> {
    if (satisfactionSurvey) {
      satisfactionSurvey = await this._apiService.put(`${this.basePath}/${id}`, satisfactionSurvey).toPromise();
    }
    return satisfactionSurvey;
  }

  async updateQuestionsOrder(id: any, questionIdList: Array<string>): Promise<void> {
    if (questionIdList) {
      await this._apiService.put(`${this.basePath}/${id}/questions-order`, questionIdList).toPromise();
    }
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}