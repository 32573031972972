import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Pagination } from '../models';
import { Products, ProductsSearchParameter } from '../models/products.model';

@Injectable()
export class ProductsService {
  public basePath: string = '/brand-product';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(product: Products): Promise<any> {
    return this._apiService
      .post(`${this.basePath}`, product).toPromise();
  }

  public getPaginated(searchParameter: ProductsSearchParameter = null): Promise<Pagination<Products>> {
    return this._apiService
    .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public getById(id: string): Promise<Products> {
    return this._apiService
    .get(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: string, product: Products): Promise<Products> {
    if (product) {
      product = await this._apiService.put(`${this.basePath}/${id}`, product).toPromise();
    }
    return product;
  }
}