import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { SchoolAcademicYear } from "../models/school-academic-year.model";

@Injectable()
export class SchoolAcademicYearService {
  public basePath: string = "/school-academic-year";

  constructor(private _apiService: ApiService) {}

  public getById(params: any): Promise<SchoolAcademicYear[]> {
    return this._apiService.get(`${this.basePath}/`, params).toPromise();
  }

  async update(schoolUnit: SchoolAcademicYear): Promise<SchoolAcademicYear> {
    return await this._apiService
      .put(`${this.basePath}/`, schoolUnit)
      .toPromise();
  }

  async createDefault(
    idSchool: string,
    idBrandProduct: string
  ): Promise<SchoolAcademicYear> {
    return await this._apiService
      .post(
        `${this.basePath}/${idSchool}/create-default`,
        { idBrandProduct },
        true
      )
      .toPromise();
  }
}
