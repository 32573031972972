import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Notification, NotificationSearchParameter, Pagination } from '../models/index';

@Injectable()
export class NotificationService {
  public basePath: string = '/notification';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public getAll(searchParameter: NotificationSearchParameter = null): Promise<Pagination<Notification>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getById(id: string): Promise<Notification> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public removeById(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public send(info: {notification: Notification, userIdList?: Array<string>, classIdList?: Array<string>})
    : Promise<Notification> {
    return this._apiService
      .post(`${this.basePath}/send`, info).toPromise();
  }

}
