export enum CourseTestTypeEnumerator {
  MULTIPLE_QUESTIONS= 1,
  FILE_UPLOAD_ANSWER= 2,
}

export const courseTestTypeEnumeratorMap: Map<CourseTestTypeEnumerator, string> = new Map<CourseTestTypeEnumerator, string>([
    [CourseTestTypeEnumerator.MULTIPLE_QUESTIONS, 'Múltipla escolha'],
    [CourseTestTypeEnumerator.FILE_UPLOAD_ANSWER, 'Envio de arquivo'],
  ]);

export const courseTestEnumeratorList: Array<{ key: number; name: string }> =
  Array.from(courseTestTypeEnumeratorMap).map((o: Array<any>) => ({
    key: o[0],
    name: o[1],
  }));