import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Config, ConfigPortaL } from '../models/config.model';

enum keys {
  configs = 'configs',
}

@Injectable()
export class ConfigService {
  public basePath: string = '/config';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public updateBulk(data: any): Promise<Config> {
    return this._apiService
      .put(`${this.basePath}/bulk`, data).toPromise();
  }

  public getSelectives(): Promise<Array<Config>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  public runDatabaseScript(): Promise<Config> {
    return this._apiService
      .post(`${this.basePath}/database-script`).toPromise();
  }

  public getConfig(): Promise<ConfigPortaL> {
    return this._apiService
      .get(`${this.basePath}`, {}, true).toPromise();
  }

  public setConfigsOnStorage(configs: ConfigPortaL): void {
    window.localStorage[keys.configs] = JSON.stringify(configs);
  }
}
