import { BaseModel, SearchParameter } from './base.model';
import { Mission } from './mission.model';

export class Campaign extends BaseModel {
  photoUrl?: string;
  title?: string;
  description?: string;
  icon?: string;
  color?: string;
  missions?: Array<Mission>;
}

export class CampaignSearchParameter extends SearchParameter {
  title?: string;
  description?: string;
}