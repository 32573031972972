import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { PopoverModule } from 'ngx-smart-popover';
import { SortablejsModule } from 'angular-sortablejs';
import { QuillModule } from 'ngx-quill';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import {
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatTooltipModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatListModule,
  MatCardModule,
} from '@angular/material';
import { FeatherModule } from 'angular-feather';
import { CustomPipesModule } from './pipes/pipe.module';
import { LoadingComponent } from './loading/loading.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LoadingInputComponent } from './loading-input/loading-input.component';

const BASE_MODULES: any = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMaskModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  NgxCoolDialogsModule,
  PopoverModule,
  FeatherModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatTabsModule,
  MatSnackBarModule,
  NgxPermissionsModule,
  MaterialFileInputModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatMomentDateModule,
  MatListModule,
  SortablejsModule,
  QuillModule,
  NgxMatSelectSearchModule,
  MatSlideToggleModule,
  NgxChartsModule,
  MatCardModule,
];

const COMPONENTS: any = [
  LoadingComponent,
  LoadingInputComponent,
];

const ENTRY_COMPONENTS: any = [];

const APP_MODULES: any = [
  AngularSvgIconModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  entryComponents: [...ENTRY_COMPONENTS],
  imports: [...BASE_MODULES, ...APP_MODULES, CustomPipesModule, NgxPermissionsModule.forChild({
    permissionsIsolate: true,
    rolesIsolate: true,
  })],
  exports: [...BASE_MODULES, ...APP_MODULES, ...COMPONENTS, CustomPipesModule, NgxPermissionsModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    } as ModuleWithProviders;
  }
}
