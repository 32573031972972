import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { QuizCompetenceSearchParameter, QuizCompetence } from '../models/quiz-competence.model';
import { Pagination } from '../models';

@Injectable()
export class QuizCompetenceService {

  public basePath: string = '/quiz-question';

  constructor(
    private _apiService: ApiService,
  ) {}

  public create(quizCompetence: QuizCompetence): Promise<QuizCompetence> {
    return this._apiService
    .post(`${this.basePath}`, quizCompetence).toPromise();
}

  public getById(id: string): Promise<QuizCompetence> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: QuizCompetenceSearchParameter = null): Promise<Pagination<QuizCompetence>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  async updateById(id: any, quizCompetence: QuizCompetence): Promise<QuizCompetence> {
    if (quizCompetence) {
      quizCompetence = await this._apiService.put(`${this.basePath}/${id}`, quizCompetence).toPromise();
    }
    return quizCompetence;
  }
}