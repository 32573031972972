export enum QuestionTypeEnumerator {
    DISCURSIVE = 1,
    DISCURSIVE_SUBITENS = 2,
    DISCURSIVE_FILE_DOWNLOAD = 3,
    FILE_UPLOAD = 4,
    DISCURSIVE_AND_FILE_UPLOAD = 5,
    FILE_UPLOAD_AND_DOWNLOAD = 6,
    OBJECTIVE_QUESTION = 7,
    OBJECTIVE_QUESTION_IMAGES = 8
}

export const questionTypeEnumeratorMap: Map<QuestionTypeEnumerator, string> = new Map<QuestionTypeEnumerator, string>([
    [QuestionTypeEnumerator.DISCURSIVE, 'Tarefa discursiva'],
    [QuestionTypeEnumerator.DISCURSIVE_SUBITENS, 'Tarefa discursiva com subitens'],
    [QuestionTypeEnumerator.DISCURSIVE_FILE_DOWNLOAD, 'Tarefa discursiva com download de arquivo'],
    [QuestionTypeEnumerator.FILE_UPLOAD, 'Tarefa com upload de arquivo como resposta'],
    [QuestionTypeEnumerator.DISCURSIVE_AND_FILE_UPLOAD, 'Tarefa discursiva com upload de arquivo'],
    [QuestionTypeEnumerator.FILE_UPLOAD_AND_DOWNLOAD, 'Tarefa com download e upload de arquivo'],
    [QuestionTypeEnumerator.OBJECTIVE_QUESTION, 'Tarefa objetiva simples'],
    [QuestionTypeEnumerator.OBJECTIVE_QUESTION_IMAGES, 'Tarefa objetiva com imagens nas alternativas'],
  ]);

export const questionTypeEnumeratorList: Array<{ key: number; name: string }> =
  Array.from(questionTypeEnumeratorMap).map((o: Array<any>) => ({
    key: o[0],
    name: o[1],
  }));

  export const isExpectedAnswers: any = (value: any): any => [1, 3, 4, 5, 6].includes(value);

  export const isObjective: any = (value: any): any => [7, 8].includes(value);