import { BaseModel } from './base.model';

export class CertificateSettings extends BaseModel {
  id?: string;
  schoolId?: string;
  schoolName?: number;

  directorSignature?: string;

  percContentViewed?: number;
  percActivitiesDone?: number;
  releaseType?: number;
  courseType?: number;
}