import { BaseModel, SearchParameter } from './base.model';

export class CourseClosureTaskAnswer extends BaseModel {
    id?: string;
    answer?: string;
    fileUrl?: string;
}

export class CourseClosureTaskAnswerSearchParameter extends SearchParameter {
    answer?: string;
  }