import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BrandConfig } from "../models/brand.model";

enum keys {
  configBrand = "configBrand",
}

@Injectable()
export class BrandConfigService {
  public basePath: string = "/brand";

  constructor(private _apiService: ApiService) {}

  public getByDomainUrl(): Promise<BrandConfig> {
    return this._apiService
      .get(`${this.basePath}/config`, {}, true)
      .toPromise();
  }

  public setConfigOnStorage(configBrand: BrandConfig): void {
    window.localStorage[keys.configBrand] = JSON.stringify(configBrand);
  }

  public getConfigOnStorage(): BrandConfig | null {
    return window.localStorage[keys.configBrand]
      ? JSON.parse(window.localStorage[keys.configBrand])
      : null;
  }
}
