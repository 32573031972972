import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Emotion, EmotionSearchParameter } from '../models/emotion.model';
import { Pagination, UserSearchParameter } from '../models';

@Injectable()
export class EmotionService {
    public basePath: string = '/emotion';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(emotion: Emotion): Promise<Emotion> {
      return this._apiService
      .post(`${this.basePath}`, emotion).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<Emotion> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  // R
  public getPaginated(searchParameter: EmotionSearchParameter = null): Promise<Pagination<Emotion>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  // U
  async update(id: any, emotion: Emotion): Promise<Emotion> {
    if (emotion) {
        emotion = await this._apiService.put(`${this.basePath}/${id}`, emotion).toPromise();
    }
    return emotion;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public export(searchParameter: UserSearchParameter = null): any {
    return this._apiService.get(`${this.basePath}/export`, sanitizeGet(searchParameter)).toPromise();
  }
}
