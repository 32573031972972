import { Component } from '@angular/core';
import { ApiService } from 'app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-containers',
  styleUrls: ['containers.component.scss'],
  template: `<router-outlet></router-outlet>`,
})
export class ContainersComponent {
  constructor(
    private _apiService: ApiService,
    private _router: Router,
  ) {
    this._apiService.setRouter(this._router);
  }
}
