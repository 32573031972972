import { AcademicYear } from 'app/core';

enum keys {
  academicYears = 'academicYears',
}

const years: Array<AcademicYear> = window.localStorage[keys.academicYears]
  ? JSON.parse(window.localStorage[keys.academicYears])
  : null;

export enum ClassYearEnumerator {

  // -=-=-= Infantil -=-=-=-
  THREEYEARS= 3,
  FOURYEARS= 4,
  FIVEYEARS= 5,

  // -=-=-= Ensino médio -=-=-=-
  FIRSTGRADE= 6,
  SECONDGRADE= 7,
  THIRDGRADE= 8,

  // -=-=-= Ensino fundamental 1 -=-=-=-
  FIRSTYEAR = 15,
  SECONDYEAR = 16,
  THIRDYEAR = 17,
  FOURTHYEAR = 18,
  FIFTHYEAR = 19,

  // -=-=-= Ensino fundamental 2 -=-=-=-
  SIXTHYEAR= 11,
  SEVENTHYEAR= 12,
  EIGHTHYEAR= 13,
  NINTHYEAR= 14,
}

export const classYearEnumeratorMap: Map<ClassYearEnumerator, string> = new Map<ClassYearEnumerator, any>(
  [
  [ClassYearEnumerator.THREEYEARS, years && '3 anos',
  ],
  [ClassYearEnumerator.FOURYEARS, years && '4 anos',
  ],
  [ClassYearEnumerator.FIVEYEARS, years && '5 anos',
  ],
  [ClassYearEnumerator.FIRSTYEAR, years && '1° ano',
  ],
  [ClassYearEnumerator.SECONDYEAR, years && '2° ano',
  ],
  [ClassYearEnumerator.THIRDYEAR, years && '3° ano',
  ],
  [ClassYearEnumerator.FOURTHYEAR, years && '4° ano',
  ],
  [ClassYearEnumerator.FIFTHYEAR, years && '5° ano',
  ],
  [ClassYearEnumerator.SIXTHYEAR, years && '6° ano',
  ],
  [ClassYearEnumerator.SEVENTHYEAR, years && '7° ano',
  ],
  [ClassYearEnumerator.EIGHTHYEAR, years && '8° ano',
  ],
  [ClassYearEnumerator.NINTHYEAR, years && '9° ano',
  ],
  [ClassYearEnumerator.FIRSTGRADE, years && '1ª série',
  ],
  [ClassYearEnumerator.SECONDGRADE, years && '2ª série',
  ],
  [ClassYearEnumerator.THIRDGRADE, years && '3ª série',
  ],
]);


export const classYearProjetEnumeratorMap: Map<ClassYearEnumerator, string> = new Map<ClassYearEnumerator, string>([
  [ClassYearEnumerator.FIRSTGRADE, years && '1ª série',
  ],
  [ClassYearEnumerator.SECONDGRADE, years && '2ª série',
  ],
  [ClassYearEnumerator.THIRDGRADE, years && '3ª série',
  ],
]);

export const classYearEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(classYearEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));

export const classYearProjetEnumeratorList: Array<{ id: number, description: string }> =
    Array.from(classYearProjetEnumeratorMap)
      .map((o: Array<any>) => ({
        id: o[0],
        description: o[1],
      }));

export const isInfantil: any = (value: any): any => [3, 4, 5].includes(value);

export const isPrimary: any = (value: any): any => [15, 16, 17, 18, 19].includes(value);

export const isSecondary: any = (value: any): any => [11, 12, 13, 14].includes(value);

export const isHighSchool: any = (value: any): any => [6, 7, 8].includes(value);
