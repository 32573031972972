export enum ClassCollectionEnumerator {
  CREATIVES = 1,
  INCREDIBLE = 2,
  INNOVATIVE = 3,
  EXTRAORDINARY = 4,
  PLENO = 5,
}

export const classCollectionEnumeratorMap:
  Map<ClassCollectionEnumerator, string> = new Map<ClassCollectionEnumerator, string>([
    [ClassCollectionEnumerator.CREATIVES, 'Criativos'],
    [ClassCollectionEnumerator.INCREDIBLE, 'Incríveis'],
    [ClassCollectionEnumerator.INNOVATIVE, 'Inovadores'],
    [ClassCollectionEnumerator.EXTRAORDINARY, 'Extraordinários'],
    [ClassCollectionEnumerator.PLENO, 'Pleno Digital'],
]);

export const classCollectionEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(classCollectionEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));