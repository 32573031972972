import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

let _router: Router;

export function sanitizeGet(obj: any): any {
  return Object.keys(obj)
    .filter((k: any) => obj[k] != null) // Remove undef. and null.
    .reduce(
      (newObj: any, k: any) => {
        if (typeof obj[k] === 'object' && obj[k].toISOString) {
          return { ...newObj, [k]: obj[k].toISOString() };
        } else if (typeof obj[k] === 'object' && obj[k].join) {
          return { ...newObj, [k]: obj[k].join(',') };
        } else if (typeof obj[k] === 'object' && obj[k].toJSON) {
          return { ...newObj, [k]: obj[k].toJSON() };
        } else if (typeof obj[k] === 'object' && obj[k].toString) {
          return { ...newObj, [k]: obj[k].toString() };
        } else if (typeof obj[k] === 'object') {
          return { ...newObj, [k]: sanitizeGet(obj[k]) };
        }
        return { ...newObj, [k]: obj[k] };
      },
      {},
    );
}

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public setRouter(router: Router): void {
    _router = router;
  }

  public get(path: string, params: any = null, isNewBack?: boolean): Observable<any> {
    return this.http.get(`${isNewBack ? environment.apiUrlNew : environment.apiUrl}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}, isNewBack?: boolean): Observable<any> {
    return this.http.put(`${isNewBack ? environment.apiUrlNew : environment.apiUrl}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}, isNewBack?: boolean): Observable<any> {
    return this.http.post(`${isNewBack ? environment.apiUrlNew : environment.apiUrl}${path}`, JSON.stringify(body))
      .pipe(timeout(600000), catchError(this.formatErrors));
  }

  public postFormData(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body)
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string, custom: object = null): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`)
      .pipe(catchError(this.formatErrors));
  }

  public deleteList(path: string, body: object = null): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${path}`, { body })
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any): any {
    if (error.status === 401) {
      _router.navigate(['']);
    }

    return throwError(error.error);
  }

}
