import { BaseModel, SearchParameter } from './base.model';
import {
  ClassYearEnumerator,
  ClassSegmentEnumerator,
  ClassCollectionEnumerator,
  ProfileTypeEnumerator,
} from '../enumerators';

export class Material extends BaseModel {
  thumbnailUrl?: string;
  fileName?: string;
  fileUrl?: string;
  parentType?: number;
  contentType?: number;
  parentId?: number;
  productType: number;
  title?: string;
  description?: string;
  folderId?: string;
  profileTypeList?: Array<ProfileTypeEnumerator> | string;
  yearList?: Array<ClassYearEnumerator> | string;
  segmentList?: Array<ClassSegmentEnumerator> | string;
  collectionList?: Array<ClassCollectionEnumerator> | string;
  parentFolder?: object;
}

export class MaterialSearchParameter extends SearchParameter {
  thumbnailUrl?: string;
  fileName?: string;
  fileUrl?: string;
  parentType?: number;
  contentType?: number;
  parentId?: number;

  title?: string;
  description?: string;
  folderId?: string;
  profileTypeList?: string;
  year?: Array<ClassYearEnumerator>;
  segment?: Array<ClassSegmentEnumerator>;
}
