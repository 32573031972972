import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Activity, ActivitySearchParameter } from '../models/activity.model';
import { Pagination } from '../models';

@Injectable()
export class ActivityService {
  public basePath: string = '/activity';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(activity: Activity): Promise<Activity> {
      return this._apiService
      .post(`${this.basePath}`, activity).toPromise();
  }

  public getById(id: string): Promise<Activity> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: any, activity: Activity): Promise<Activity> {
    if (activity) {
      activity = await this._apiService.put(`${this.basePath}/${id}`, activity).toPromise();
    }
    return activity;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  async updateQuestionsOrder(id: any, questionIdList: Array<string>): Promise<void> {
    if (questionIdList) {
      await this._apiService.put(`${this.basePath}/${id}/question-order`, questionIdList).toPromise();
    }
  }

  public getCountReport(searchParameter: any): Promise<any> {
    return this._apiService
        .get(`${this.basePath}/count-report`, sanitizeGet(searchParameter)).toPromise();
  }

  public getReport(searchParameter: any): Promise<any> {
    return this._apiService
        .get(`${this.basePath}/report`, sanitizeGet(searchParameter)).toPromise();
  }

  public getUserAnswers(id: string, userId: string): Promise<any> {
    return this._apiService
        .get(`${this.basePath}/${id}/answers`, sanitizeGet({ userId })).toPromise();
  }
}
