import { ApiService, sanitizeGet } from './api.service';
import { BusinessPlan, BusinessPlanSearchParameter, Pagination } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class BusinessPlanService {
  public basePath: string = '/business-plan';

  constructor(private _apiService: ApiService) { }

  public create(satisfactionSurvey: BusinessPlan): Promise<BusinessPlan> {
    return this._apiService
      .post(`${this.basePath}`, satisfactionSurvey).toPromise();
  }

  public getById(id: string): Promise<BusinessPlan> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: BusinessPlanSearchParameter = null): Promise<Pagination<any>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  async update(id: any, satisfactionSurvey: BusinessPlan): Promise<BusinessPlan> {
    if (satisfactionSurvey) {
      satisfactionSurvey = await this._apiService.put(`${this.basePath}/${id}`, satisfactionSurvey).toPromise();
    }
    return satisfactionSurvey;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}