import { BaseModel, SearchParameter } from './base.model';
import { EmotionTypeEnumerator } from '../enumerators/emotion-type.enumerator';
import { TaskChoice } from './task-choice.model';
import { QuizCompetence } from './quiz-competence.model';
import { MissionTypeEnumerator } from '../enumerators';

export class Task extends BaseModel {
  photoUrl?: string;
  description?: string;
  type?: number;
  emotionType?: EmotionTypeEnumerator;
  order?: number;
  missionId?: string;
  taskChoices?: Array<TaskChoice>;
  questions?: Array<QuizCompetence>;
  videoUrl?: string;
  feedPhotoUrl: string;
}

export class TaskSearchParameter extends SearchParameter {
  description?: string;
  type?: number;
  typeList?: string;
  emotionTypeList?: Array<number>;
  order?: number;
  missionId?: string;
  missionType?: MissionTypeEnumerator;
  missionTitle?: string;
  odsIdList?: Array<string>;
  competenceIdList?: Array<string>;
  campaignIdList?: Array<string>;
  classYear?: number;
}