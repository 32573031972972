import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Competence, CompetenceSearchParameter } from '../models/competence.model';
import { Pagination } from '../models';

@Injectable()
export class CompetenceService {
  public basePath: string = '/competence';

  constructor(private _apiService: ApiService) { }

  // C
  public create(competence: Competence): Promise<Competence> {
    return this._apiService
      .post(`${this.basePath}`, competence).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<Competence> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  // R
  public getPaginated(searchParameter: CompetenceSearchParameter = null): Promise<Pagination<Competence>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  // R
  public getSelectives(): Promise<Array<{ id: string, title: string }>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  // U
  async update(id: any, competence: Competence): Promise<Competence> {
    if (competence) {
      competence = await this._apiService.put(`${this.basePath}/${id}`, competence).toPromise();
    }
    return competence;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

}