import { BaseModel, SearchParameter } from './base.model';
import { Mission } from './mission.model';

export class Projet extends BaseModel {
    title?: string;
    description?: string;
    videoUrl?: string;
    order?: number;
    missions?: Array<Mission>;
}

export class ProjetSearchParameter extends SearchParameter {
    title?: string;
  }