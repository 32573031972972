export enum FilterContentTypeEnumerator {
  TITLE = 1,
  CODE = 2,
}

export const filterContentTypeEnumeratorMap: Map<
  FilterContentTypeEnumerator,
  string
> = new Map<FilterContentTypeEnumerator, string>([
  [FilterContentTypeEnumerator.TITLE, "Título"],
  [FilterContentTypeEnumerator.CODE, "Código"],
]);

export const filterContentEnumeratorList: Array<{ key: number; name: string }> =
  Array.from(filterContentTypeEnumeratorMap).map((o: Array<any>) => ({
    key: o[0],
    name: o[1],
  }));
