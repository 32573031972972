export enum EmotionTypeEnumerator {
  SAD = 1,
  ANGRY = 2,
  AFRAID = 3,
  HAPPY = 4,
}

export const emotionTypeEnumeratorMap:
    Map<EmotionTypeEnumerator, string> = new Map<EmotionTypeEnumerator, string>([
      [EmotionTypeEnumerator.SAD, 'Tristeza'],
      [EmotionTypeEnumerator.ANGRY, 'Raiva'],
      [EmotionTypeEnumerator.AFRAID, 'Medo'],
      [EmotionTypeEnumerator.HAPPY, 'Alegria'],
]);

export const emotionTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(emotionTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
