export enum LmsContentTypeEnumerator {
  TEXTAREA = 1,
  HTML = 2,
  PDF = 3,
  AUDIO = 4,
  EBOOK = 5,
}

export const lmsContentTypeEnumeratorMap: Map<
  LmsContentTypeEnumerator,
  string
> = new Map<LmsContentTypeEnumerator, string>([
  [LmsContentTypeEnumerator.TEXTAREA, "Campo de texto"],
  [LmsContentTypeEnumerator.HTML, "HTML"],
  [LmsContentTypeEnumerator.PDF, "PDF"],
  [LmsContentTypeEnumerator.AUDIO, "Áudio"],
  [LmsContentTypeEnumerator.EBOOK, "Ebook"],
]);

export const lmsContentTypeEnumeratorList: Array<{
  id: number;
  description: string;
}> = Array.from(lmsContentTypeEnumeratorMap).map((o: Array<any>) => ({
  id: o[0],
  description: o[1],
}));
