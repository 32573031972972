export enum AccessControlListTypeEnumerator {
  CAN_ADD = 1,
  CAN_VIEW = 2,
  CAN_UPDATE = 3,
  CAN_DELETE = 4,
}

export const accessControlListTypeEnumeratorMap:
  Map<AccessControlListTypeEnumerator, string> = new Map<AccessControlListTypeEnumerator, string>([
  [AccessControlListTypeEnumerator.CAN_ADD, 'Pode adicionar'],
  [AccessControlListTypeEnumerator.CAN_VIEW, 'Pode visualizar'],
  [AccessControlListTypeEnumerator.CAN_UPDATE, 'Pode editar'],
  [AccessControlListTypeEnumerator.CAN_DELETE, 'Pode deletar'],
]);

export const accessControlListTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(accessControlListTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
