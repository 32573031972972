
// -=-=-=- Question answer type -=-=-=-=-

export enum SatisfactionSurveyQuestionAnswerType {
  ALTERNATIVE = 1,
  DESCRIPTIVE = 2,
  ALTERNATIVE_DESCRIPTIVE = 3,
}

export const satisfactionSurveyQuestionAnswerListTypeEnumeratorMap:
  Map<SatisfactionSurveyQuestionAnswerType, string> = new Map<SatisfactionSurveyQuestionAnswerType, string>([
  [SatisfactionSurveyQuestionAnswerType.ALTERNATIVE, 'Alternativas'],
  [SatisfactionSurveyQuestionAnswerType.DESCRIPTIVE, 'Descritiva'],
  [SatisfactionSurveyQuestionAnswerType.ALTERNATIVE_DESCRIPTIVE, 'Alternativas e descritiva'],
]);

export const satisfactionSurveyQuestionAnswerListTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(satisfactionSurveyQuestionAnswerListTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));

// -=-=-=- Question answer type -=-=-=-=-

export enum SatisfactionSurveyQuestionChoiceType {
  SINGLE = 1,
  MULTIPLE = 2,
}

export const satisfactionSurveyQuestionChoiceListTypeEnumeratorMap:
  Map<SatisfactionSurveyQuestionChoiceType, string> = new Map<SatisfactionSurveyQuestionChoiceType, string>([
  [SatisfactionSurveyQuestionChoiceType.SINGLE, 'Única escolha'],
  [SatisfactionSurveyQuestionChoiceType.MULTIPLE, 'Múltipla escolha'],
]);

export const satisfactionSurveyQuestionChoiceListTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(satisfactionSurveyQuestionChoiceListTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));