import { ApiService } from './api.service';
import { Banner, Pagination } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class BannerService {
  public basePath: string = '/banner';

  constructor(private _apiService: ApiService) { }

  public create(banner: Banner): Promise<Banner> {
    return this._apiService
      .post(`${this.basePath}`, banner).toPromise();
  }

  public getById(id: string): Promise<Banner> {
    return this._apiService
      .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: any): Promise<Pagination<Banner>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public getSelectives(): Promise<Array<{ id: string, title: string }>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  async update(id: any, competence: Banner): Promise<Banner> {
    if (competence) {
      competence = await this._apiService.put(`${this.basePath}/${id}`, competence).toPromise();
    }
    return competence;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public updateOrder(bannerIdList: Array<string>, applicationType): Promise<void> {
    return this._apiService
      .post(`${this.basePath}/order`, { bannerIdList, applicationType })
      .toPromise();
  }
}