export const language: any = {
  lang: 'pt-br',
  data: {
    MAT_PAGINATOR: {
      ITEMS_PER_PAGE: 'Itens por pág.',
      NEXT_PAGE: 'Próxima pág.',
      PREVIOUS_PAGE: 'Pág. anterior',
      FIRST_PAGE: 'Primeira pág.',
      LAST_PAGE: 'Última pág.',
      OF: 'de',
    },
  },
};
