import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { CourseTest, CourseTestSearchParameter } from '../models/course-test.model';
import { Pagination } from '../models';

@Injectable()
export class CourseTestService {
  public basePath: string = '/course-test';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(courseTest: CourseTest): Promise<CourseTest> {
      return this._apiService
      .post(`${this.basePath}`, courseTest).toPromise();
  }

  public getById(id: string): Promise<CourseTest> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: any, courseTest: CourseTest): Promise<CourseTest> {
    if (courseTest) {
      courseTest = await this._apiService.put(`${this.basePath}/${id}`, courseTest).toPromise();
    }
    return courseTest;
  }

  async redo(id: any, data: any): Promise<CourseTest> {
    return this._apiService.put(`${this.basePath}/${id}/redo`, data).toPromise();
  }

  async done(id: any, data: any): Promise<CourseTest> {
    return this._apiService.put(`${this.basePath}/${id}/done`, data).toPromise();
  }

  async updateQuestionsOrder(id: any, questionIdList: Array<string>): Promise<void> {
    if (questionIdList) {
      await this._apiService.put(`${this.basePath}/${id}/questions-order`, questionIdList).toPromise();
    }
  }

  public getAnswersReport(searchParameter: any): Promise<any> {
    return this._apiService
        .get(`${this.basePath}/answers-report`, sanitizeGet(searchParameter)).toPromise();
  }
}
