import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Pagination, UserSearchParameter } from '../models';
import { TaskAnswerComment, TaskAnswerCommentSearchParameter } from '../models/task-answer-comment.model';

@Injectable()
export class TaskAnswerCommentService {
  public basePath: string = '/task-answer-comment';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(data: TaskAnswerComment): Promise<TaskAnswerComment> {
    return this._apiService
      .post(`${this.basePath}`, data).toPromise();
  }

  public getPaginated(searchParameter: TaskAnswerCommentSearchParameter = null): Promise<Pagination<TaskAnswerComment>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
