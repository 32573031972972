import { BaseModel, SearchParameter } from './base.model';
import { StudentClass } from './student-class.model';
import { MissionTypeEnumerator, TaskTypeEnumerator } from '../enumerators';
import { Competence } from './competence.model';
import { QuizCompetence } from './quiz-competence.model';
import { TaskChoice } from './task-choice.model';
import { Ods } from './ods.model';

export class TaskAnswer extends BaseModel {
  sendDate: Date;
  answer: string;
  user: {
    name: string,
    studentClass: StudentClass,
  };
  task: {
    type: number,
    title: string,
    taskChoices?: Array<TaskChoice>,
    questions?: Array<QuizCompetence>,
    description?: string,
    mission: {
      photoUrl: string
      type: number,
      title: string,
      competence?: Competence,
      ODS?: Ods,
      checkpoint?: boolean,
    },
  };
}

export class TaskAnswerSearchParameter extends SearchParameter {
  studentName?: string;
  missionType?: MissionTypeEnumerator;
  taskType?: TaskTypeEnumerator;
  missionId?: string;
  startSendDate?: Date;
  endSendDate?: Date;
  competenceIdList?: Array<string>;
  studentClassIdList?: Array<string>;
  projetIdList?: Array<string>;
  taskOrder?: number;
}