import { BaseModel, SearchParameter } from './base.model';

export class UserPrivateChat extends BaseModel {
    userId?: string;
    privateChatId?: string;
    lastViewedAt?: string;
}

export class UserPrivateChatSearchParameter extends SearchParameter {
    userId?: string;
    privateChatId?: string;
  }