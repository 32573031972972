import { BaseModel, SearchParameter } from './base.model';
import { Course } from 'app/core';

export class BusinessPlan extends BaseModel {
    id?: string;
    name?: string;
    productList?: any;
    courseIdList?: string;
    courses?: Array<Course>;
}

export class BusinessPlanSearchParameter extends SearchParameter {
    name?: string;
  }