import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet} from './api.service';
import { UserPrivateChatMessage, UserPrivateChatMessageSearchParameter } from '../models/user-private-chat-message.model';

@Injectable()
export class UserPrivateChatMessageService {
  public basePath: string = '/user-private-chat-message';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(userPrivateChatMessage: UserPrivateChatMessage): Promise<UserPrivateChatMessage> {
    return this._apiService
    .post(`${this.basePath}`, userPrivateChatMessage).toPromise();
  }

  public getAll(searchParameter: UserPrivateChatMessageSearchParameter = null): Promise<any> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }
}
