import { BaseModel, SearchParameter } from './base.model';
import { User } from './user.model';

export class TaskAnswerComment extends BaseModel {
  userId: string;
  taskAnswerId: string;
  comment: string;
  sendDate: Date;
  user: User;
};

export class TaskAnswerCommentSearchParameter extends SearchParameter {
  taskAnswerId?: string;
}
