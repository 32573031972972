import { BaseModel, SearchParameter } from './base.model';

export class Activity extends BaseModel {
    id?: string;
    title?: string;
    description?: string;
}

export class ActivitySearchParameter extends SearchParameter {
    title?: string;
    description?: string;
  }