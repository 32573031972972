import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { CourseClosureTaskAnswer } from '../models/course-closure-task-answer.model';
import { Pagination } from '../models';

@Injectable()
export class CourseClosureTaskAnswerService {
  public basePath: string = '/course-closure-task-answer';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(courseClosureTaskAnswer: CourseClosureTaskAnswer): Promise<CourseClosureTaskAnswer> {
      return this._apiService
      .post(`${this.basePath}`, courseClosureTaskAnswer).toPromise();
  }

  public getById(id: string): Promise<CourseClosureTaskAnswer> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  async redo(id: any, data: any): Promise<CourseClosureTaskAnswer> {
    return this._apiService.put(`${this.basePath}/${id}/redo`, data).toPromise();
  }

  async done(id: any, data: any): Promise<CourseClosureTaskAnswer> {
    return this._apiService.put(`${this.basePath}/${id}/done`, data).toPromise();
  }

  public getReport(searchParameter: any): Promise<CourseClosureTaskAnswer> {
    return this._apiService
        .get(`${this.basePath}/report`, sanitizeGet(searchParameter)).toPromise();
  }
}
