import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { VirtualGroup, VirtualGroupSearchParameter } from '../models/virtual-group.model';
import { User, UserSearchParameter } from '../models/user.model';
import { Pagination } from '../models';

@Injectable()
export class VirtualGroupService {
  public basePath: string = '/virtual-group';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(virtualGroup: VirtualGroup): Promise<VirtualGroup> {
      return this._apiService
      .post(`${this.basePath}`, virtualGroup).toPromise();
  }

  public getById(id: string): Promise<VirtualGroup> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getCourseContentScheduleById(id: string): Promise<VirtualGroup> {
    return this._apiService
        .get(`${this.basePath}/${id}/course-content-schedule`).toPromise();
  }

  public getPaginated(searchParameter: VirtualGroupSearchParameter = null): Promise<Pagination<VirtualGroup>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getStudents(id: string, searchParameter: UserSearchParameter = null): Promise<Pagination<User>> {
    return this._apiService
      .get(`${this.basePath}/${id}/students`, sanitizeGet(searchParameter)).toPromise();
  }

  async addStudents(id: any, data: any): Promise<void> {
    if (data) {
      await this._apiService.put(`${this.basePath}/${id}/add-students`, data).toPromise();
    }
  }

  async removeStudents(id: any, data: any): Promise<void> {
    if (data) {
      await this._apiService.put(`${this.basePath}/${id}/remove-students`, data).toPromise();
    }
  }

  async update(id: any, virtualGroup: VirtualGroup): Promise<VirtualGroup> {
    if (virtualGroup) {
      virtualGroup = await this._apiService.put(`${this.basePath}/${id}`, virtualGroup).toPromise();
    }
    return virtualGroup;
  }

  async updateCourseContentScheduleById(id: any, data: any): Promise<any> {
    return this._apiService.put(`${this.basePath}/${id}/course-content-schedule`, data).toPromise();
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  public close(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}/close`).toPromise();
  }
}
