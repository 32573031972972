import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { environment } from 'env/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
  ) {
    // add
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUserToken: string = this._authService.getCookieToken('admin-cookie/token');
    this._authService.setToken(currentUserToken);
    const baseReactUrl = environment.reactUrl;

    if (currentUserToken) {
      const currentUser: User = await this._userService.getLoggedUser();
      if (currentUser) {
        if (!route.data.profileTypes.includes(currentUser.profileType)) {
          this.router.navigate(['/error/403']);
          return false;
        }
        return true;
      }
    }

    window.location.href = `${baseReactUrl}/`;
    this._snackBar.open('Você precisa entrar no sistema para continuar.');
    return false;
  }
}
