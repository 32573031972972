import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContainersComponent } from 'app/containers/containers.component';
import { AuthGuard } from 'app/core';
import { accessControlList } from './access.config';

const routes: Routes = [
  {
    path: '',
    component: ContainersComponent,
    children: [
      {
        canActivate: [AuthGuard],
        data: {profileTypes: accessControlList.canView},
        path: 'dashboard',
        loadChildren: 'app/containers/dashboard/dashboard.component.module#DashboardComponentModule',
      },
      {
        path: 'forgot-password',
        loadChildren: 'app/containers/forgot-password/forgot-password.component.module#ForgotPasswordComponentModule',
      },
      {
        path: 'login',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: 'error',
        loadChildren: 'src/app/containers/error-page/error-page.component.module#ErrorPageComponentModule',
      },
      {
        path: 'termos',
        loadChildren: 'src/app/containers/terms/terms.component.module#TermsComponentModule',
      },
      {
        path: 'politica',
        loadChildren: 'src/app/containers/policy/policy.component.module#PolicyComponentModule',
      },
      {
        path: 'ajuda',
        loadChildren: 'src/app/containers/help/help.component.module#HelpComponentModule',
      },
      {
        path: 'redirect',
        loadChildren: 'src/app/containers/redirect/redirect.component.module#RedirectComponentModule',
      },
      {
        path: '',
        loadChildren: 'app/containers/login/login.component.module#LoginComponentModule',
      },
      {path: '**', redirectTo: '/error/404'},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContainersRoutingModule {
}
