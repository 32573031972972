import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Tag, TagSearchParameter } from '../models/tag.model';
import { Pagination } from '../models';

@Injectable()
export class TagService {
  public basePath: string = '/tag';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(tag: Tag): Promise<Tag> {
      return this._apiService
      .post(`${this.basePath}`, tag).toPromise();
  }

  public getById(id: string): Promise<Tag> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getPaginated(searchParameter: TagSearchParameter = null): Promise<Pagination<Tag>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  async update(id: any, tag: Tag): Promise<Tag> {
    if (tag) {
      tag = await this._apiService.put(`${this.basePath}/${id}`, tag).toPromise();
    }
    return tag;
  }

  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
