import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class UtilsService {
  public basePath: string = '/utils';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public createUrl(file: FormData): Promise<string> {
    return this._apiService
      .postFormData(`${this.basePath}/upload-image`, file).toPromise();
  }

  public validateEmail(str: string) {
    const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return exp.test(str);
  }

}
