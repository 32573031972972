import { Banner } from './banner.model';
import { BaseModel } from './base.model';

export class Config extends BaseModel {
  id?: string;
  name?: string;
  type?: number;
  value?: string;
}

export class ConfigPortaL {
  id?: string;
  faq?: string;
  chatbot?: string;
  facebook?: string;
  loginColor?: string;
  loginLogo?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  defaultEmail?: string;
  twitter?: string;
  idBrand?: string;
  banners?: Banner[];
  studentTutorialVideo?: string;
  teacherTutorialVideo?: string;
  familyTutorialVideo?: string;
  primaryColor?: string;
  secondaryColor?: string;
}
