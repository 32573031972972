import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(
    private translate: TranslateService,
  ) {
    super();
    this.translate.onLangChange.subscribe((e: Event) => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of: string = this.translate ? this.translate.instant('MAT_PAGINATOR.OF') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex: number = ((page * pageSize) > length) ?
      (Math.ceil(length / pageSize) - 1) * pageSize :
      page * pageSize;

    const endIndex: number = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  translateLabels(): void {
    this.firstPageLabel = this.translate.instant('MAT_PAGINATOR.FIRST_PAGE');
    this.itemsPerPageLabel = this.translate.instant('MAT_PAGINATOR.ITEMS_PER_PAGE');
    this.lastPageLabel = this.translate.instant('MAT_PAGINATOR.LAST_PAGE');
    this.nextPageLabel = this.translate.instant('MAT_PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('MAT_PAGINATOR.PREVIOUS_PAGE');
  }
}
