export enum MaterialParentTypeEnumerator {
  DASHBOARD = 1,
  ODS = 2,
  COMPETENCE = 3,
}

export const materialParentTypeEnumeratorMap:
  Map<MaterialParentTypeEnumerator, string> = new Map<MaterialParentTypeEnumerator, string>([
  [MaterialParentTypeEnumerator.DASHBOARD, 'Dashboard'],
  [MaterialParentTypeEnumerator.ODS, 'ODS'],
  [MaterialParentTypeEnumerator.COMPETENCE, 'Competência'],
]);

export const materialParentTypeEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(materialParentTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
