const enum SchoolCapacityType {
  STUDENT = 1,
  FAMILY = 2,
  TEACHER = 3,
  SCHOOL_ADMIN = 4,
}

export const staffCapacityTypeEnumeratorMap: Map<SchoolCapacityType, string> = new Map<SchoolCapacityType, string>([
  [SchoolCapacityType.SCHOOL_ADMIN, 'Coordenador'],
  [SchoolCapacityType.TEACHER, 'Professor'],
]);

export const staffCapacityTypeEnumetorList: Array<{ id: number, description: string }> =
  Array
    .from(staffCapacityTypeEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));

export default SchoolCapacityType;
