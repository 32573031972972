import { BaseModel, SearchParameter } from './base.model';

export class AlternativeType {
  description: string;
  fileUrl: string;
  isCorrect: boolean;
}

export class ActivityQuestion extends BaseModel {
    id?: string;
    description?: string;
    activityId?: string;
    questionType?: number;
    alternatives?: AlternativeType[];
    answer?: string;
    correctAnswer?: number;
    mediaUrl?: string;
    testOptions?: AlternativeType[];
}

export class ActivityQuestionSearchParameter extends SearchParameter {
    description?: string;
  }
