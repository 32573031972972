import { BaseModel } from './base.model';
import {
  ClassYearEnumerator,
  ClassSegmentEnumerator,
  ClassCollectionEnumerator,
  ProfileTypeEnumerator,
} from '../enumerators';

export class Teacher extends BaseModel {
  id: string;
  name: string;
  classId: string;
  schoolId: string;
  email: string;
  cellphone: string;
  profileType: ProfileTypeEnumerator;
  academicRecord: string;
  pushToken: string;
  deviceType: string;
  lastAccess: Date;
  teacherStudentClasses: [
      {
          id: string;
          userId: string;
          classId: string;
          studentClass: {
              id: string;
              name: string;
              activeODSId: string;
              schoolId: string;
              year: ClassYearEnumerator;
              segment: ClassSegmentEnumerator;
              collection: ClassCollectionEnumerator;
              projet: boolean;
          }
      }
  ];
  edoolsId?: string;
}