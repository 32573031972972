export enum ClosureTaskStatus {
  NOT_AVAILABLE = 1,
  TODO = 2,
  WAITING_REVIEW = 3,
  DONE = 4,
  REDO = 5,
}

export const closureTaskStatusEnumeratorMap: Map<ClosureTaskStatus, string> = new Map<ClosureTaskStatus, string>([
  [ClosureTaskStatus.NOT_AVAILABLE, 'Não disponível'],
  [ClosureTaskStatus.TODO, 'A fazer'],
  [ClosureTaskStatus.WAITING_REVIEW, 'Aguardando Revisão'],
  [ClosureTaskStatus.DONE, 'Aprovado'],
  [ClosureTaskStatus.REDO, 'Refazer'],
]);

export const closureTaskStatusList: Array<any> =
  Array.from(closureTaskStatusEnumeratorMap)
    .map((o: Array<any>) => {
      const color: string = getStatusColor(o[0]);

      return {
        id: o[0],
        description: o[1],
        color,
      };
    });

function getStatusColor(key: any): string {
  let color: string = null;

  switch (key) {
    case ClosureTaskStatus.NOT_AVAILABLE:
      color = 'rgb(180, 180, 180)';
      break;
    case ClosureTaskStatus.TODO:
      color = 'rgb(56, 101, 163)';
      break;
    case ClosureTaskStatus.WAITING_REVIEW:
      color = 'rgb(255, 204, 1)';
      break;
    case ClosureTaskStatus.DONE:
      color = 'rgb(100, 163, 53)';
      break;
    case ClosureTaskStatus.REDO:
      color = 'rgb(225, 59, 36)';
      break;
  }

  return color;
}