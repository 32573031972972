import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { CourseTestQuestion, CourseTestQuestionSearchParameter } from '../models/course-test-question.model';
import { Pagination } from '../models';

@Injectable()
export class CourseTestQuestionService {
  public basePath: string = '/course-test-question';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public create(courseTest: CourseTestQuestion): Promise<CourseTestQuestion> {
      return this._apiService
      .post(`${this.basePath}`, courseTest).toPromise();
  }

  public getById(id: string): Promise<CourseTestQuestion> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  async update(id: any, courseTest: CourseTestQuestion): Promise<CourseTestQuestion> {
    if (courseTest) {
      courseTest = await this._apiService.put(`${this.basePath}/${id}`, courseTest).toPromise();
    }
    return courseTest;
  }

  async remove(id: string): Promise<CourseTestQuestion> {
    return this._apiService.delete(`${this.basePath}/${id}`).toPromise();
  }

  async removeQuestionOption(id: string): Promise<CourseTestQuestion> {
    return this._apiService.delete(`${this.basePath}-option/${id}`).toPromise();
  }
}
