export enum CourseTypeEnumerator {
  ELECTIVE = 1,
  TRAIL = 2,
}

export const courseTypeEnumeratorMap: Map<CourseTypeEnumerator, string> =
  new Map<CourseTypeEnumerator, string>([
    [CourseTypeEnumerator.ELECTIVE, "LMS Alunos"],
    [CourseTypeEnumerator.TRAIL, "LMS Educadores"],
  ]);

export const courseTypeEnumeratorList: Array<{
  id: number;
  description: string;
}> = Array.from(courseTypeEnumeratorMap).map((o: Array<any>) => ({
  id: o[0],
  description: o[1],
}));
