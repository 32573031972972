import { BaseModel, SearchParameter } from './base.model';

export class Module extends BaseModel {
    title?: string;
    description?: string;
    order?: number;
}

export class ModuleSearchParameter extends SearchParameter {
    title?: string;
    description?: string;
}