import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { UserMission, Pagination, UserMissionSearchParameter } from '../models/index';

@Injectable()
export class UserMissionService {
  public basePath: string = '/user-mission';

  constructor(
    private _apiService: ApiService,
  ) { }

  public getAll(searchParameter: UserMissionSearchParameter = null): Promise<Pagination<UserMission>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  public getProgressData(searchParameter: UserMissionSearchParameter = null): Promise<Pagination<UserMission>> {
    return this._apiService
      .get(`${this.basePath}/progress`, sanitizeGet(searchParameter)).toPromise();
  }

  public getUserTasksWithAnswers(userMissionId: string): Promise<any> {
    return this._apiService
      .get(`${this.basePath}/user-answers/${userMissionId}`).toPromise();
  }

}