import { BaseModel, SearchParameter } from './base.model';
import {
    MissionTypeEnumerator,
    ClassSegmentEnumerator,
    ClassCollectionEnumerator,
    ClassYearEnumerator,
} from '../enumerators';
import { Task } from './task.model';

export class Mission extends BaseModel {
    id: string;
    photoUrl: string;
    title: string;
    externalId: string;
    type: MissionTypeEnumerator;
    startAt: Date;
    endAt: Date;
    tasks: Array<Task>;
    segment: ClassSegmentEnumerator;
    collection: ClassCollectionEnumerator;
    year: ClassYearEnumerator;
    order: number;
    description: string;
    feedPhotoUrl: string;
    checkpoint: boolean;
    checkpointTitle: string;
    notificationTitle: string;
    notificationDescription: string;
    featuredStartAt: Date;
    featuredEndAt: Date;
    projet: any;
}

export class MissionSearchParameter extends SearchParameter {
    title?: string;
    type?: number;
    typeList?: Array<number>;
    startAt?: Date;
    endAt?: Date;
    initStartAt?: Date;
    finishEndAt?: Date;
    segment?: ClassSegmentEnumerator;
    collection?: ClassCollectionEnumerator;
    year?: ClassYearEnumerator;
    segmentList?: Array<ClassSegmentEnumerator>;
    collectionList?: Array<ClassCollectionEnumerator>;
    yearList?: Array<ClassYearEnumerator>;
    competenceIdList?: Array<string>;
    odsIdList?: Array<string>;
    campaignIdList?: Array<string>;
    projetId?: string;
    projet?: boolean;
    orderBy?: string;
    isOfTheWeek?: boolean;
}
