import { BaseModel, SearchParameter } from './base.model';
import { Competence } from './competence.model';
import {
  ClassYearEnumerator,
  ClassSegmentEnumerator,
  ClassCollectionEnumerator,
} from '../enumerators';

export class QuizCompetence extends BaseModel {
  competenceId: string;
  description: string;
  competence: Competence;
  yearList: Array<ClassYearEnumerator> | string;
  segmentList: Array<ClassSegmentEnumerator> | string;
  collectionList: Array<ClassCollectionEnumerator> | string;
  taskId: string;
}

export class QuizCompetenceSearchParameter extends SearchParameter {
  description?: string;
  competenceTitle?: string;
  year?: Array<ClassYearEnumerator>;
  segment?: Array<ClassSegmentEnumerator>;
  collection?: Array<ClassCollectionEnumerator>;
}