import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Content, ContentSearchParameter } from '../models/content.model';
import { Pagination } from '../models';

@Injectable()
export class ContentService {
  public basePath: string = '/content';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(content: Content): Promise<Content> {
      return this._apiService
      .post(`${this.basePath}`, content).toPromise();
  }

  // R by Id
  public getCompleteById(id: string): Promise<Content> {
    return this._apiService
        .get(`${this.basePath}/${id}/complete`).toPromise();
  }

  // R
  public getPaginated(searchParameter: ContentSearchParameter = null): Promise<Pagination<Content>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  // U
  async update(id: any, content: Content): Promise<Content> {
    if (content) {
      content = await this._apiService.put(`${this.basePath}/${id}`, content).toPromise();
    }
    return content;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }

  async updateActivitiesOrder(id: any, activityIdList: Array<string>): Promise<void> {
    if (activityIdList) {
      await this._apiService.put(`${this.basePath}/${id}/activity-order`, activityIdList).toPromise();
    }
  }
}
