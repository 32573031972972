import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Pagination } from '../models';

@Injectable()
export class CertificateService {
  public basePath: string = '/certificate';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  public sendManually(courseUser: any): Promise<any> {
      return this._apiService
      .post(`${this.basePath}/manually`, courseUser).toPromise();
  }
}
