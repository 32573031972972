import { Injectable } from '@angular/core';
import { User } from '../models';

enum keys {
  token = 'token',
  refreshToken = 'refresh_token',
  logged_user = 'logged_user',
}

@Injectable()
export class AuthService {

  public getToken(): string {
    return window.localStorage[keys.token];
  }

  public setToken(token: string): void {
    window.localStorage[keys.token] = token;
  }

  public setRefreshToken(refreshToken: string): void {
    window.localStorage[keys.refreshToken] = refreshToken;
  }

  public getCookieToken(key: string) {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const decodedCookieArray = decodedCookie.split(';');
    for (let i = 0; i < decodedCookieArray.length; i++) {
      let cookieElement = decodedCookieArray[i];
      while (cookieElement.charAt(0) == ' ') {
        cookieElement = cookieElement.substring(1);
      }
      if (cookieElement.indexOf(name) == 0) {
        return cookieElement.substring(name.length, cookieElement.length);
      }
    }
    return "";
  }

  public setCookieToken(key: string, data: string, domain?: string, exdays?: number) {
    const date = new Date();
    date.setTime(date.getTime() + ((exdays || 1) * 24 * 60 * 60 * 1000));

    const expires = "expires=" + date.toUTCString();
    document.cookie = key + "=" + data + ";" + expires + ";domain=" + domain + ";path=/";
  }

  public deleteCookieToken(key: string, path?: string, domain?: string) {
    if (this.getCookieToken(key)) {
      document.cookie = key + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  public setUser(user: User): void {
    window.localStorage[keys.logged_user] = JSON.stringify(user);
  }

  public getUser(): User {
    return window.localStorage[keys.logged_user] ? JSON.parse(window.localStorage[keys.logged_user]) : null;
  }

  public destroyUser(): void {
    window.localStorage.removeItem(keys.token);
    window.localStorage.removeItem(keys.logged_user);
  }

}
