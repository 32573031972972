export enum SelectedBannerTypeEnumerator {
  ELECTIVE = 1,
  TRAIL = 2,
}

export const selectedBannerTypeEnumeratorMap: Map<
  SelectedBannerTypeEnumerator,
  string
> = new Map<SelectedBannerTypeEnumerator, string>([
  [SelectedBannerTypeEnumerator.ELECTIVE, "LMS Alunos"],
  [SelectedBannerTypeEnumerator.TRAIL, "LMS Educadores"],
]);
