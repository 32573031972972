import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Pagination } from '../models';
import { OdsContent, OdsContentSearchParameter } from '../models/ods-content.model';

@Injectable()
export class OdsContentService {
  public basePath: string = '/ods-content';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(odsContent: OdsContent): Promise<OdsContent> {
      return this._apiService
      .post(`${this.basePath}`, odsContent).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<OdsContent> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  // R
  public getPaginated(searchParameter: OdsContentSearchParameter = null): Promise<Pagination<OdsContent>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  // R with material
  public getWithMaterial(id: string): Promise<OdsContent> {
    return this._apiService
      .get(`${this.basePath}/${id}/with-material`).toPromise();
  }

  // U
  async update(id: any, odsContent: OdsContent): Promise<OdsContent> {
    if (odsContent) {
        odsContent = await this._apiService.put(`${this.basePath}/${id}`, odsContent).toPromise();
    }
    return odsContent;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
