import { BaseModel, SearchParameter } from './base.model';
import { User } from './user.model';
import { Mission } from './mission.model';
import { Task } from './task.model';

export class UserPost extends BaseModel {
  id?: string;
  description?: string;
  photoUrl?: string;
  user?: User;
  mission?: Mission;
  task?: Task;
}

export class UserPostSearchParameter extends SearchParameter {
  description?: string;
  userName?: string;
  missionId?: string;
  taskId?: string;
  studentClassId?: string;
  studentClassIdList?: Array<string>;
}