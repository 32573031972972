export enum DocumentEnumerator {
  DRIVER_LICENSE = 1,
  PASSAPORT = 2,
}

export const documentEnumeratorMap: Map<DocumentEnumerator, string> = new Map<DocumentEnumerator, string>([
  [DocumentEnumerator.DRIVER_LICENSE, 'driver license'],
  [DocumentEnumerator.PASSAPORT, 'passaport'],
]);

export const documentEnumeratorList: Array<{ id: number, description: string }> =
  Array.from(documentEnumeratorMap)
    .map((o: Array<any>) => ({
      id: o[0],
      description: o[1],
    }));
