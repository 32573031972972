import { Injectable } from '@angular/core';
import { ApiService, sanitizeGet } from './api.service';
import { Pagination } from '../models';
import { SchoolUnit, SchoolUnitSearchParameter } from '../models/school-unit';

@Injectable()
export class SchoolUnitService {
  public basePath: string = '/school-unit';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(schoolUnit: SchoolUnit): Promise<SchoolUnit> {
    return this._apiService
    .post(`${this.basePath}`, schoolUnit).toPromise();
  }

  public getSelectives(): Promise<SchoolUnit> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<SchoolUnit> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  // R
  public getPaginated(searchParameter: SchoolUnitSearchParameter = null): Promise<Pagination<SchoolUnit>> {
    return this._apiService
      .get(`${this.basePath}`, sanitizeGet(searchParameter)).toPromise();
  }

  // U
  async update(id: string, schoolUnit: SchoolUnit): Promise<SchoolUnit> {
    if (schoolUnit) {
      schoolUnit = await this._apiService.put(`${this.basePath}/${id}`, schoolUnit).toPromise();
    }
    return schoolUnit;
  }

  async changeMain(id: string, mainId: SchoolUnit): Promise<SchoolUnit> {
    if (mainId) {
      mainId = await this._apiService.put(`${this.basePath}/${id}/main`, mainId).toPromise();
    }
    return mainId;
  }

  public addSchools(id: string, schoolUnit: SchoolUnit): Promise<SchoolUnit> {
    return this._apiService
    .put(`${this.basePath}/${id}/schools`, schoolUnit).toPromise();
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
