import { BaseModel, SearchParameter } from './base.model';
import { Module } from './module.model';

export class Tag extends BaseModel {
    name?: string;
    type?: number;
}

export class TagSearchParameter extends SearchParameter {
    name?: string;
    type?: number;
}