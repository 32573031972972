import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Ods, OdsSearchParameter } from '../models/ods.model';
import { Pagination } from '../models';

@Injectable()
export class OdsService {
  public basePath: string = '/ods';

  constructor(
    private _apiService: ApiService,
  ) {
  }

  // C
  public create(ods: Ods): Promise<Ods> {
      return this._apiService
      .post(`${this.basePath}`, ods).toPromise();
  }

  // R by Id
  public getById(id: string): Promise<Ods> {
    return this._apiService
        .get(`${this.basePath}/${id}`).toPromise();
  }

  public getComplete(id: string): Promise<Ods> {
    return this._apiService
        .get(`${this.basePath}/${id}/complete`).toPromise();
  }

  // R
  public getPaginated(searchParameter: OdsSearchParameter = null): Promise<Pagination<Ods>> {
    return this._apiService
      .get(`${this.basePath}`, searchParameter).toPromise();
  }

  public getSelectives(): Promise<Array<{id: string, title: string}>> {
    return this._apiService
      .get(`${this.basePath}/selectives`).toPromise();
  }

  // R with content
  public getWithContent(id: string): Promise<Ods> {
    return this._apiService
      .get(`${this.basePath}/${id}/with-content`).toPromise();
  }

  // U
  async update(id: any, ods: Ods): Promise<Ods> {
    if (ods) {
      ods = await this._apiService.put(`${this.basePath}/${id}`, ods).toPromise();
    }
    return ods;
  }

  // D
  public remove(id: string): Promise<void> {
    return this._apiService
      .delete(`${this.basePath}/${id}`).toPromise();
  }
}
