import { BaseModel, SearchParameter } from './base.model';

export class UserPrivateChatMessage extends BaseModel {
      userId?: string;
      privateChatId?: string;
      message?: string;
      photoUrl?: string;
}

export class UserPrivateChatMessageSearchParameter extends SearchParameter {
    userId?: string;
    privateChatId?: string;
    message?: string;
  }