import { BaseModel, SearchParameter } from './base.model';

export class CourseTestQuestion extends BaseModel {
    id?: string;
    description?: string;
    courseTestId?: string;
    order?: number;
}

export class CourseTestQuestionSearchParameter extends SearchParameter {
    description?: string;
  }