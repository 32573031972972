import { ClassCollectionEnumerator, ClassSegmentEnumerator, ClassYearEnumerator } from '../enumerators';
import { BaseModel, SearchParameter } from './base.model';
import { Mission } from './mission.model';
import { StudentClass } from './student-class.model';

export class MissionSchedule extends BaseModel {
    id: string;
    mission: Mission;
    studentClass: StudentClass;
    startAt: Date;
    endAt: Date;
    isActive: boolean;
}

export class MissionScheduleSearchParameter extends SearchParameter {
    missionId?: string;
    missionIdList?: Array<string>;
    studentClassIdList?: Array<string>;
    studentClassId?: string;
    isOfTheWeek?: boolean;
    yearList?: Array<ClassYearEnumerator>;
    segmentList?: Array<ClassSegmentEnumerator>;
    collectionList?: Array<ClassCollectionEnumerator>;
    type: number;
}
